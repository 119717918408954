import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800" }
const _hoisted_2 = { class: "text-lg text-gray-800 dark:text-gray-100" }
const _hoisted_3 = { class: "w-full mt-2 p-4" }
const _hoisted_4 = { class: "pb-2 mb-3 border-b border-dashed border-gray-300 dark:border-gray-800 flex justify-start space-x-2" }

import { onMounted,ref,computed,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton,Container,InputBox, Dropdown } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, DynamicSite, BoardMaster,Member, ValidateHelper, KeyValuePair, SiteRepository, BoardTypes, ReturnValues } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'MaseterSet',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));
const boardTypes = computed(() => {
    return [
        { key:'일반', value:BoardTypes.Normal },
        { key:'관리자전용', value:BoardTypes.ReadOnly },
        { key:'갤러리', value:BoardTypes.Gallery },
        { key:'질의응답', value:BoardTypes.Question }
    ];
});
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var boardRep = new BoardRepository(config.apis.board, '', slug.value);
var siteRep = new SiteRepository(config.apis.site, '');
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    category:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    data:new DynamicSite(),
    masters:[] as BoardMaster[],
    categories:[] as KeyValuePair[]
});

onMounted(async () => {
  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : 'notice';
  pageinfo.value.id = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '';

  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
    siteRep = new SiteRepository(config.apis.site, pageinfo.value.accessToken);
    pageinfo.value.isLoggedIn = true;
  }

  await getBoard();
  await getMaster();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

watch(() => slug.value,
    async (newValue) => {
        console.log(newValue);
        await getBoard();
        await getMaster();
        pageinfo.value.key += 1;
    }
);

const getBoard = async () => {
  let rst = await siteRep.GetSiteDetail(slug.value);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
    let rst = await boardRep.GetMasters({ curPage : 1, pageSize : 50, keyword : '' });
    if (rst.check) {
      pageinfo.value.masters = rst.data;
    }
}

const fnDelete = (item:BoardMaster) => {
  MessageBox.Confirm("정말 삭제하시겠습니까?", async () => {
    let rst = await boardRep.MasterRemove(item.id);
    if (rst.check) {
      MessageBox.Success("삭제되었습니다.", async () => {
        await getMaster();
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}

const fnSave = async (item:BoardMaster) => {
  let rst = new ReturnValues();

  if (validate.IsNullOrWhiteSpace(item.masterCode)) {
    MessageBox.Alert("마스터 코드를 입력하세요.");
    return;
  }

  if (validate.IsNullOrWhiteSpace(item.title)) {
    MessageBox.Alert("제목을 입력하세요.");
    return;
  }

  if (validate.IsNullOrWhiteSpace(item.boardType)) {
    MessageBox.Alert("게시판 타입을 선택하세요.");
    return;
  }

  boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
  if (item.id !== null && item.id !== undefined && item.id.trim() !== '') {
    rst = await boardRep.MasterModify(item.id, item.masterCode, slug.value, item.title, item.boardType);
  } else {
    rst = await boardRep.MasterRegist(item.masterCode, slug.value, item.title, item.boardType);
  }

  if (rst.check) {
    MessageBox.Success("저장되었습니다.", async () => {
      await getMaster();
    });
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnAdd = () => {
    pageinfo.value.masters.push(new BoardMaster());
};

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: `board_setup_${_unref(pageinfo).key}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(pageinfo).data.name) + " 게시판 설정", 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(StyleButton), {
                size: "md",
                class: "rounded-md shadow-md py-1 px-3",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (fnAdd()))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("추가")
                ])),
                _: 1
              })
            ]),
            (_openBlock(), _createElementBlock("div", {
              class: "w-full text-gray-700 dark:text-gray-200 flex flex-col space-y-2",
              key: `board_setup_list_${_unref(pageinfo).key}`
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageinfo).masters, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "flex space-x-2",
                  key: `master_${index}`
                }, [
                  _createVNode(_unref(InputBox), {
                    class: "border border-gray-300 rounded-md text-gray-500 px-2 py-1",
                    modelValue: item.title,
                    "onUpdate:modelValue": ($event: any) => ((item.title) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(InputBox), {
                    class: "border border-gray-300 rounded-md text-gray-500 px-2 py-1",
                    modelValue: item.masterCode,
                    "onUpdate:modelValue": ($event: any) => ((item.masterCode) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(Dropdown), {
                    placeholder: "분류를 선택하세요.",
                    class: "px-2 py-1 rounded-md text-gray-500",
                    items: boardTypes.value,
                    modelValue: item.boardType,
                    "onUpdate:modelValue": ($event: any) => ((item.boardType) = $event)
                  }, null, 8, ["items", "modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(StyleButton), {
                    size: "md",
                    class: "rounded-md shadow-md py-1 px-3",
                    onClick: ($event: any) => (fnSave(item))
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("저장")
                    ])),
                    _: 2
                  }, 1032, ["onClick"]),
                  (item.id !== null && item.id !== undefined && String(item.id).trim() !== '')
                    ? (_openBlock(), _createBlock(_unref(StyleButton), {
                        key: 0,
                        size: "md",
                        class: "rounded-md shadow-md py-1 px-3",
                        onClick: ($event: any) => (fnDelete(item))
                      }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("삭제")
                        ])),
                        _: 2
                      }, 1032, ["onClick"]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})