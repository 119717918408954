import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800" }
const _hoisted_2 = { class: "text-lg text-gray-800 dark:text-gray-100" }
const _hoisted_3 = { class: "w-full mt-2 py-2" }
const _hoisted_4 = { class: "dark:text-gray-300" }
const _hoisted_5 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_6 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_7 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_8 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_9 = { class: "w-full mt-1 pt-1 flex justify-between" }
const _hoisted_10 = { class: "flex justify-start space-x-2" }

import { onMounted,ref,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, PagingParameter,BoardContent, BoardMaster } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'MasterList',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();

var boardRep = new BoardRepository(config.apis.board, '', '');
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    slug:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:[] as BoardContent[],
    master:new BoardMaster()
});

onMounted(async () => {
  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : '';
  pageinfo.value.slug = (route.params.slug !== null && route.params.slug !== undefined) ? String(route.params.slug) : '';

  boardRep = new BoardRepository(config.apis.board, '', pageinfo.value.slug);

  await getMaster();
  await getBoards();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

watch(
  () => route.params, // params 전체를 감시
  async (newParams, oldParams) => {
    console.log('newParams : ', newParams);

    site.setValue('loading', true);
    pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : '';
    pageinfo.value.slug = (route.params.slug !== null && route.params.slug !== undefined) ? String(route.params.slug) : '';

    boardRep = new BoardRepository(config.apis.board, '', pageinfo.value.slug);

    await getMaster();
    await getBoards();

    pageinfo.value.key += 1;

    setTimeout(() => {
      site.setValue('loading', false);
    }, 200);
  },
  { deep: true } // 객체 내부를 깊게 감시
);

const getBoards = async () => {
  let rst = await boardRep.GetBoards(pageinfo.value.masterCode, pageinfo.value.paging);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    let rst = await boardRep.GetMaster(pageinfo.value.masterCode);
    console.log('getMaster : ', rst);
    if (rst.check) {
      pageinfo.value.master = rst.data;
      pageinfo.value.totalCount = rst.code;
    }
}

const fnPaging = async (num:number) => {
  pageinfo.value.paging.curPage = num;
  await getBoards();
  pageinfo.value.key += 1;
}

const fnView = (item:BoardContent) => {
  console.log(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/${item.id}`);
  router.push(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/${item.id}`);
}

const fnNew = () => {
  router.push(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/edit`);
}

const fnCategory = () => {
  router.push(`/board/category/${pageinfo.value.slug}/${pageinfo.value.masterCode}`);
}

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: `mainpage_${_unref(pageinfo).key}`
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_unref(Head), {
            title: _unref(t)(`common.${_unref(pageinfo).master.title}`),
            slug: _unref(pageinfo).slug || '',
            key: `head_${_unref(pageinfo).key}`
          }, null, 8, ["title", "slug"])),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(t)(`common.${_unref(pageinfo).master.title}`)), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_unref(DataGrid), {
                items: _unref(pageinfo).data,
                rowclick: fnView
              }, {
                columns: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "NO", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Title", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Read", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "RegistDate", -1)
                ])),
                items: _withCtx(({ item, index }) => [
                  _createElementVNode("td", _hoisted_5, _toDisplayString((_unref(pageinfo).totalCount - index)), 1),
                  _createElementVNode("td", _hoisted_6, _toDisplayString(item.title), 1),
                  _createElementVNode("td", _hoisted_7, _toDisplayString(item.readCount), 1),
                  _createElementVNode("td", _hoisted_8, _toDisplayString(_unref(dayjs).utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
                ]),
                _: 1
              }, 8, ["items"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_unref(StyleButton), {
                  size: "sm",
                  class: "rounded-md shadow-md px-3 pb-0.5 pt-1",
                  onClick: fnNew
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode("새글")
                  ])),
                  _: 1
                }),
                _createVNode(_unref(StyleButton), {
                  size: "sm",
                  class: "rounded-md shadow-md px-3 pb-0.5 pt-1",
                  onClick: fnCategory
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("카테고리 관리리")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_unref(Pagination), {
                "cur-page": _unref(pageinfo).paging.curPage,
                "page-size": _unref(pageinfo).paging.pageSize,
                "total-count": _unref(pageinfo).totalCount,
                callback: fnPaging,
                class: "overflow-hidden rounded-md"
              }, null, 8, ["cur-page", "page-size", "total-count"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})