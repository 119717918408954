<template>
<StyleButton :class="componentInfo.class" :size="componentInfo.size" v-if="componentInfo.isLoaded" :key="`LogoutButton_${componentInfo.key}`">
    <i class="fa-solid fa-right-from-bracket"></i>
    <span class="hidden lg:block pl-1">SIGN OUT</span>
</StyleButton>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { StyleButton } from '@gn2studio/gn2components';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    clickEvent?:Function|null|undefined
};

const property = defineProps<containerParameter>();
const auth = new AuthService();
const memberStore = useMemberStore();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'sm'
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
    componentInfo.value.isLoaded = true;
});
</script>