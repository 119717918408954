import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800" }
const _hoisted_2 = { class: "text-lg text-gray-800 dark:text-gray-100" }
const _hoisted_3 = { class: "w-full mt-2 p-4" }
const _hoisted_4 = { class: "w-full text-gray-700 dark:text-gray-200" }
const _hoisted_5 = { class: "text-lg font-bold" }
const _hoisted_6 = { class: "w-full mt-3 py-2 border-t border-dashed border-gray-300 dark:border-gray-600 flex justify-between" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "w-full py-2 border-t border-dashed border-gray-300 dark:border-gray-600 flex justify-between" }
const _hoisted_9 = { class: "flex justify-start space-x-2" }

import { onMounted,ref,computed } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton, Container } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, BoardContent, BoardMaster,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'MasterView',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));

var boardRep = new BoardRepository(config.apis.board, '', slug.value);
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    data:new BoardContent(),
    master:new BoardMaster()
});

onMounted(async () => {
  pageinfo.value.isLoaded = true;

  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : 'notice';
  pageinfo.value.id = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '';

  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
    pageinfo.value.isLoggedIn = true;
  }

  await getMaster();
  await getBoard();

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

const getBoard = async () => {
  let rst = await boardRep.GetContent(pageinfo.value.id);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    let rst = await boardRep.GetMaster(pageinfo.value.masterCode);
    if (rst.check) {
      pageinfo.value.master = rst.data;
    }
}

const fnList = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}`);
}

const fnEdit = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}/edit/${pageinfo.value.id}`);
}

const fnNew = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}/edit`);
}

const fnDelete = () => {
  MessageBox.Confirm('정말로 삭제하시겠습니까?', async () => {
    let rst = await boardRep.DeleteContent(pageinfo.value.id);
    if (rst.check) {
      MessageBox.Success('삭제했습니다.', () => {
        fnList();
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: `mainpage_${_unref(pageinfo).key}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_unref(t)(`common.${_unref(pageinfo).master.title}`)), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, _toDisplayString(_unref(pageinfo).data.title), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, "Read: " + _toDisplayString(_unref(pageinfo).data.readCount), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("span", null, _toDisplayString(_unref(dayjs).utc(_unref(pageinfo).data.condition.registDate).local().format('YYYY-MM-DD HH:mm')), 1)
                ])
              ]),
              _createElementVNode("div", {
                class: "w-full py-2 border-t border-dashed border-gray-300 dark:border-gray-600",
                innerHTML: _unref(pageinfo).data.content
              }, null, 8, _hoisted_7),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", null, [
                  _createVNode(_unref(StyleButton), {
                    size: "md",
                    class: "rounded-md shadow-md py-1 px-3",
                    onClick: fnList
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("목록")
                    ])),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_unref(StyleButton), {
                    size: "md",
                    class: "rounded-md shadow-md py-1 px-3",
                    onClick: fnDelete
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode("삭제")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(StyleButton), {
                    size: "md",
                    class: "rounded-md shadow-md py-1 px-3",
                    onClick: fnEdit
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode("수정")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(StyleButton), {
                    size: "md",
                    class: "rounded-md shadow-md py-1 px-3",
                    onClick: fnNew
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("새글")
                    ])),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})