import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "bg-gray-600 text-white text-center py-6 rounded-md" }
const _hoisted_3 = { class: "text-2xl font-bold" }
const _hoisted_4 = { class: "flex justify-between space-x-2 pt-5" }
const _hoisted_5 = { class: "p-6 space-y-6 text-gray-700 dark:text-white w-2/3" }
const _hoisted_6 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "flex flex-col border-b pb-4" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "flex flex-col border-b pb-4" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = { class: "" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "" }
const _hoisted_28 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_29 = { class: "" }
const _hoisted_30 = { class: "" }
const _hoisted_31 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_32 = { class: "" }
const _hoisted_33 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_34 = { class: "" }
const _hoisted_35 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_36 = { class: "" }
const _hoisted_37 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_38 = { class: "" }
const _hoisted_39 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_40 = { class: "" }
const _hoisted_41 = { class: "w-1/3 border border-gray-400" }
const _hoisted_42 = { class: "space-x-1" }
const _hoisted_43 = { class: "flex justify-end p-6" }

import { computed, onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,Image, BooleanBadge } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { ArticleRepository, PagingParameter,Member, V_Member, Book, HtmlHelper, StringHelper } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookView',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const Id = computed(() => route.params.id);

var articleRep = new ArticleRepository(config.apis.article, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:new Book(),
    member:new Member()
});

onMounted(async () => {
    if (Id.value === null || Id.value === undefined || String(Id.value).trim() === '') {
        router.push(`/error/500`);
    }

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.member = memberStore.member;
        articleRep = new ArticleRepository(config.apis.article, pageinfo.value.accessToken);
    }

    await getMember();

    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 200);
});

const getMember = async () => {
  let rst = await articleRep.GetBookDetailForManager(String(Id.value));
  console.log(rst);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnList = async () => {
    router.push(`/book/list`);
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(Container), { class: "p-8" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_unref(pageinfo).data.title), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-medium" }, "ID:", -1)),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(pageinfo).data.id), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "font-medium" }, "Description:", -1)),
              _createElementVNode("p", {
                innerHTML: (_unref(pageinfo).data.description !== null && _unref(pageinfo).data.description !== undefined) ? _unref(StringHelper).convertNewlinesToBr(_unref(pageinfo).data.description) : ''
              }, null, 8, _hoisted_9)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "font-medium" }, "Synopsis:", -1)),
              _createElementVNode("p", {
                innerHTML: (_unref(pageinfo).data.synopsis !== null && _unref(pageinfo).data.synopsis !== undefined) ? _unref(StringHelper).convertNewlinesToBr(_unref(pageinfo).data.synopsis) : ''
              }, null, 8, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-medium" }, "keywords:", -1)),
              _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(pageinfo).data.keywords), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-medium" }, "cover:", -1)),
              _createElementVNode("span", _hoisted_15, [
                _createVNode(_unref(Image), {
                  src: _unref(pageinfo).data.cover,
                  class: "w-32 h-32"
                }, null, 8, ["src"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-medium" }, "thumbnail:", -1)),
              _createElementVNode("span", _hoisted_17, [
                _createVNode(_unref(Image), {
                  src: _unref(pageinfo).data.thumbnail,
                  class: "w-32 h-32"
                }, null, 8, ["src"])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, "Like : " + _toDisplayString(_unref(pageinfo).data.likeCount), 1),
              _createElementVNode("span", _hoisted_20, "Favorite : " + _toDisplayString(_unref(pageinfo).data.favoriteCount), 1),
              _createElementVNode("span", _hoisted_21, "ReviewCount : " + _toDisplayString(_unref(pageinfo).data.reviewCount), 1),
              _createElementVNode("span", _hoisted_22, "PlannedCount : " + _toDisplayString(_unref(pageinfo).data.plannedCount), 1),
              _createElementVNode("span", _hoisted_23, "Read : " + _toDisplayString(_unref(pageinfo).data.readCount), 1)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, [
                _cache[6] || (_cache[6] = _createTextVNode("isReviewOpen : ")),
                _createVNode(_unref(BooleanBadge), {
                  check: _unref(pageinfo).data.isReviewOpen ?? false
                }, null, 8, ["check"])
              ]),
              _createElementVNode("span", _hoisted_26, [
                _cache[7] || (_cache[7] = _createTextVNode("isPublish : ")),
                _createVNode(_unref(BooleanBadge), {
                  check: _unref(pageinfo).data.isPublish ?? false
                }, null, 8, ["check"])
              ]),
              _createElementVNode("span", _hoisted_27, [
                _cache[8] || (_cache[8] = _createTextVNode("isUseAssistant : ")),
                _createVNode(_unref(BooleanBadge), {
                  check: _unref(pageinfo).data.isUseAssistant ?? false
                }, null, 8, ["check"])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _cache[9] || (_cache[9] = _createElementVNode("span", { class: "font-medium" }, "majorCategoryId:", -1)),
              _createElementVNode("span", _hoisted_29, _toDisplayString(_unref(pageinfo).data.majorCategory), 1),
              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "font-medium" }, "minorCategoryId:", -1)),
              _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(pageinfo).data.minorCategory), 1)
            ]),
            _createElementVNode("div", _hoisted_31, [
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "font-medium" }, "Writer:", -1)),
              _createElementVNode("span", _hoisted_32, _toDisplayString(_unref(pageinfo).data.writer?.nickname), 1)
            ]),
            _createElementVNode("div", _hoisted_33, [
              _cache[12] || (_cache[12] = _createElementVNode("span", { class: "font-medium" }, "Owner:", -1)),
              _createElementVNode("span", _hoisted_34, _toDisplayString(_unref(pageinfo).data.owner.nickName), 1)
            ]),
            _createElementVNode("div", _hoisted_35, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "font-medium" }, "Registration Date:", -1)),
              _createElementVNode("span", _hoisted_36, _toDisplayString(_unref(pageinfo).data.condition.registDate ? _unref(dayjs).utc(_unref(pageinfo).data.condition.registDate).local().format('YYYY-MM-DD HH:mm') : ''), 1)
            ]),
            _createElementVNode("div", _hoisted_37, [
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "font-medium" }, "Status:", -1)),
              _createElementVNode("span", _hoisted_38, _toDisplayString(_unref(pageinfo).data.condition.status), 1)
            ]),
            _createElementVNode("div", _hoisted_39, [
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "font-medium" }, "tags:", -1)),
              _createElementVNode("span", _hoisted_40, _toDisplayString(_unref(pageinfo).data.tags), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_41, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageinfo).data.chapters, (chapter, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `chapter_${i}`,
                class: "p-6 space-y-6 text-gray-700 dark:text-white w-full"
              }, [
                _createElementVNode("h3", null, _toDisplayString(chapter.title), 1),
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chapter.articles, (article, j) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: `article_${j}`,
                      class: "border-b pt-4"
                    }, [
                      _createVNode(_component_router_link, {
                        to: "#",
                        class: "space-x-2"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, "[" + _toDisplayString(article.seq) + "]", 1),
                          _createElementVNode("span", null, _toDisplayString(article.title), 1),
                          _createElementVNode("span", _hoisted_42, [
                            _createElementVNode("span", null, "read : " + _toDisplayString(article.readCount), 1),
                            _createElementVNode("span", null, "like : " + _toDisplayString(article.likeCount), 1),
                            _createElementVNode("span", null, "share : " + _toDisplayString(article.shareCount), 1),
                            _createElementVNode("span", null, "publishAt : " + _toDisplayString(article.publishedAt), 1)
                          ])
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_43, [
          _createVNode(_unref(StyleButton), {
            class: "ml-2 py-2 px-4",
            onClick: fnList
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode("List")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})