<template>
  <Container :key="`mainpage_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <div class="w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800">
      <h3 class="text-lg text-gray-800 dark:text-gray-100">{{ t(`common.${pageinfo.master.title}`) }}</h3>
    </div>
    <div class="w-full mt-2 p-4">
      <div class="w-full text-gray-700 dark:text-gray-200">
        <h3 class="text-lg font-bold">{{ pageinfo.data.title }}</h3>
        <div class="w-full mt-3 py-2 border-t border-dashed border-gray-300 dark:border-gray-600 flex justify-between">
          <div>
            <span>Read: {{ pageinfo.data.readCount }}</span>
          </div>
          <div>
            <span>{{ dayjs.utc(pageinfo.data.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</span>
          </div>
        </div>
        <div class="w-full py-2 border-t border-dashed border-gray-300 dark:border-gray-600" v-html="pageinfo.data.content"></div>
        <div class="w-full py-2 border-t border-dashed border-gray-300 dark:border-gray-600 flex justify-between">
          <div>
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnList">목록</StyleButton>
          </div>
          <div class="flex justify-start space-x-2">
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnDelete">삭제</StyleButton>
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnEdit">수정</StyleButton>
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnNew">새글</StyleButton>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { onMounted,ref,computed } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton, Container } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, BoardContent, BoardMaster,Member } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));

var boardRep = new BoardRepository(config.apis.board, '', slug.value);
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    data:new BoardContent(),
    master:new BoardMaster()
});

onMounted(async () => {
  pageinfo.value.isLoaded = true;

  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : 'notice';
  pageinfo.value.id = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '';

  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
    pageinfo.value.isLoggedIn = true;
  }

  await getMaster();
  await getBoard();

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

const getBoard = async () => {
  let rst = await boardRep.GetContent(pageinfo.value.id);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    let rst = await boardRep.GetMaster(pageinfo.value.masterCode);
    if (rst.check) {
      pageinfo.value.master = rst.data;
    }
}

const fnList = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}`);
}

const fnEdit = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}/edit/${pageinfo.value.id}`);
}

const fnNew = () => {
  router.push(`/board/${slug.value}/${pageinfo.value.masterCode}/edit`);
}

const fnDelete = () => {
  MessageBox.Confirm('정말로 삭제하시겠습니까?', async () => {
    let rst = await boardRep.DeleteContent(pageinfo.value.id);
    if (rst.check) {
      MessageBox.Success('삭제했습니다.', () => {
        fnList();
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}
</script>
