<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { Globalizer } from '@gn2studio/gn2.library.common';

onMounted(() => {
  Globalizer.Init();
});

</script>