import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore } from '@/store';
import { TextBox, Container, Jumbotron } from '@gn2studio/gn2components';
import { Head } from '@/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {

const auth = new AuthService();
const site = useGlobalStore();

var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(async () => {
  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: `mainpage_${_unref(pageinfo).key}`
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(Head), { title: "" }),
          _createVNode(_unref(Jumbotron), {
            title: "test",
            description: "testest"
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})