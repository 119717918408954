<template>
  <Container :key="`memberpage_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <div class="w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800">
      <h3 class="text-lg text-gray-800 dark:text-gray-100">도서관리</h3>
    </div>
    <div class="w-full mt-2 py-2">
      <div class="dark:text-gray-300">
        <DataGrid :items="pageinfo.data" :rowclick="fnView">
            <template #columns>
              <th class="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">NO</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Title</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Writer</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Owner</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Category</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Status</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">RegistDate</th>
            </template>
            <template #items="{ item, index }">
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-center">{{ (pageinfo.totalCount - index) }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-left">{{ item.title }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.writer?.nickname }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.owner.nickName }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.majorCategory }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.condition.status }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400 text-center">{{ item.condition.registDate ? dayjs.utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm') : '' }}</td>
            </template>
        </DataGrid>
      </div>
      <div class="w-full mt-1 pt-1 flex justify-between">
        <div class="flex justify-start space-x-2">
        </div>
        <Pagination :cur-page="pageinfo.paging.curPage" :page-size="pageinfo.paging.pageSize" :total-count="pageinfo.totalCount" :callback="fnPaging" class="overflow-hidden rounded-md" />
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { ArticleRepository, PagingParameter,Member, V_Member, Book } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();

var articleRep = new ArticleRepository(config.apis.article, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:[] as Book[],
    member:new Member()
});

onMounted(async () => {
  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    articleRep = new ArticleRepository(config.apis.article, pageinfo.value.accessToken);
  }

  await getBooks();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

const getBooks = async () => {
  let rst = await articleRep.GetBookListForManager(pageinfo.value.paging);
  if (rst.check) {
    pageinfo.value.data = rst.data;
    console.log('pageinfo.value.data : ', pageinfo.value.data);
    pageinfo.value.totalCount = rst.code;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnPaging = async (num:number) => {
  pageinfo.value.paging.curPage = num;
  await getBooks();
  pageinfo.value.key += 1;
}

const fnView = (item:V_Member) => {
  router.push(`/book/view/${item.id}`);
}
</script>
