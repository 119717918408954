<template>
<Container class="p-8">
    <div class="">
    <!-- Header -->
    <div class="bg-gray-600 text-white text-center py-6 rounded-md">
        <h3 class="text-2xl font-bold">{{ pageinfo.data.title }}</h3>
    </div>

    <div class="flex justify-between space-x-2 pt-5">
        <div class="p-6 space-y-6 text-gray-700 dark:text-white w-2/3">
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">ID:</span>
            <span class="">{{ pageinfo.data.id }}</span>
            </div>
            <div class="flex flex-col border-b pb-4">
            <p class="font-medium">Description:</p>
            <p v-html="(pageinfo.data.description !== null && pageinfo.data.description !== undefined) ? StringHelper.convertNewlinesToBr(pageinfo.data.description) : ''"></p>
            </div>
            <div class="flex flex-col border-b pb-4">
            <p class="font-medium">Synopsis:</p>
            <p v-html="(pageinfo.data.synopsis !== null && pageinfo.data.synopsis !== undefined) ? StringHelper.convertNewlinesToBr(pageinfo.data.synopsis) : ''"></p>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">keywords:</span>
            <span class="">{{ pageinfo.data.keywords }}</span>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">cover:</span>
            <span class="">
                <Image :src="pageinfo.data.cover" class="w-32 h-32" />
            </span>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">thumbnail:</span>
            <span class="">
                <Image :src="pageinfo.data.thumbnail" class="w-32 h-32" />
            </span>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="">Like : {{ pageinfo.data.likeCount }}</span>
            <span class="">Favorite : {{ pageinfo.data.favoriteCount }}</span>
            <span class="">ReviewCount : {{ pageinfo.data.reviewCount }}</span>
            <span class="">PlannedCount : {{ pageinfo.data.plannedCount }}</span>
            <span class="">Read : {{ pageinfo.data.readCount }}</span>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="">isReviewOpen : <BooleanBadge :check="pageinfo.data.isReviewOpen ?? false" /></span>
            <span class="">isPublish : <BooleanBadge :check="pageinfo.data.isPublish ?? false" /></span>
            <span class="">isUseAssistant : <BooleanBadge :check="pageinfo.data.isUseAssistant ?? false" /></span>
            </div>




            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">majorCategoryId:</span>
            <span class="">
                {{ pageinfo.data.majorCategory }}
            </span>
            <span class="font-medium">minorCategoryId:</span>
            <span class="">
                {{ pageinfo.data.minorCategory }}
            </span>
            </div>

            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">Writer:</span>
            <span class="">{{ pageinfo.data.writer?.nickname }}</span>
            </div>
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">Owner:</span>
            <span class="">{{ pageinfo.data.owner.nickName }}</span>
            </div>

            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">Registration Date:</span>
            <span class="">{{ pageinfo.data.condition.registDate ? dayjs.utc(pageinfo.data.condition.registDate).local().format('YYYY-MM-DD HH:mm') : '' }}</span>
            </div>
            <!-- Status -->
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">Status:</span>
            <span class="">{{ pageinfo.data.condition.status }}</span>
            </div>
            <!-- User Type -->
            <div class="flex items-center justify-between border-b pb-4">
            <span class="font-medium">tags:</span>
            <span class="">{{ pageinfo.data.tags }}</span>
            </div>
        </div>
        <div class="w-1/3 border border-gray-400">
            <div v-for="(chapter, i) in pageinfo.data.chapters" :key="`chapter_${i}`" class="p-6 space-y-6 text-gray-700 dark:text-white w-full">
                <h3>{{ chapter.title }}</h3>
                <ul>
                    <li v-for="(article, j) in chapter.articles" :key="`article_${j}`" class="border-b pt-4">
                        <router-link to="#" class="space-x-2">
                            <span>[{{  article.seq }}]</span>
                            <span>{{  article.title }}</span>
                            <span class="space-x-1">
                                <span>read : {{  article.readCount }}</span>
                                <span>like : {{  article.likeCount }}</span>
                                <span>share : {{  article.shareCount }}</span>
                                <span>publishAt : {{  article.publishedAt }}</span>
                            </span>
                        </router-link>

                    </li>
                </ul>
            </div>
        </div>
    </div>


    <!-- Footer -->
    <div class="flex justify-end p-6">
        <StyleButton class="ml-2 py-2 px-4" @click="fnList">List</StyleButton>
    </div>
    </div>
</Container>
</template>

<script setup lang="ts">
import { computed, onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,Image, BooleanBadge } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { ArticleRepository, PagingParameter,Member, V_Member, Book, HtmlHelper, StringHelper } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const Id = computed(() => route.params.id);

var articleRep = new ArticleRepository(config.apis.article, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:new Book(),
    member:new Member()
});

onMounted(async () => {
    if (Id.value === null || Id.value === undefined || String(Id.value).trim() === '') {
        router.push(`/error/500`);
    }

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.member = memberStore.member;
        articleRep = new ArticleRepository(config.apis.article, pageinfo.value.accessToken);
    }

    await getMember();

    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 200);
});

const getMember = async () => {
  let rst = await articleRep.GetBookDetailForManager(String(Id.value));
  console.log(rst);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnList = async () => {
    router.push(`/book/list`);
}
</script>
