<template>
    <div class="w-full border-b border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-900 sky" v-if="componentInfo.isLoaded" :key="`sky-menu-${componentInfo.key}`">
    <div class="flex justify-between">
            <div>
                <Tab :items="componentInfo.tabitems" size="xs" itemclass="px-3 py-1 rounded-t-md" class="ml-3 h-[31px] items-end" :onclick="TabClick" />
            </div>
            <div>
                <div class="flex space-x-1 pr-3">
                <ProfileButton class="rounded-lg h-[24px] mt-1 px-3" size="xs" :member="componentInfo.member" @click="fnProfile"></ProfileButton>
                <LogoutButton class="rounded-lg h-[24px] mt-1 px-3" size="xs" @click="fnLogout"></LogoutButton>
                <ThemeButton />
                <LanguageSelector />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ProfileButton,LogoutButton,LanguageSelector } from '@/components';
import { ThemeButton,Tab } from '@gn2studio/gn2components';
import { useRouter } from 'vue-router';
import { onMounted, ref, onUnmounted } from 'vue';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';
import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { MessageBox } from '@/utility';

const { t, locale } = useI18n();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();
const currentPath = ref(location.pathname);
const validate = new ValidateHelper();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false,
    tabitems:[
        { active:false, title:'GN2Studio' },
        { active:false, title:'MuseStudio' },
        { active:false, title:'Merrytoktok' }
    ]
});

onMounted(async () => {
    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
        componentInfo.value.member = memberStore.member
        if (componentInfo.value.member === null 
        || componentInfo.value.member === undefined 
        || componentInfo.value.member.id === null 
        || componentInfo.value.member.id === undefined 
        || String(componentInfo.value.member.id).trim() === '')
        {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
            componentInfo.value.member = rst.data;
            
        } else {
            await auth.refreshSilent();
        }
            memberStore.setMember(rst.data);
        }

        componentInfo.value.isLogin = true;
        componentInfo.value.isLoaded = true;
    }

    window.addEventListener('popstate', () => {
        currentPath.value = location.pathname;
    });
});

onUnmounted(() => {
    window.removeEventListener('popstate', () => {
        currentPath.value = location.pathname;
    });
});

const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
        await auth.logout();
        memberStore.clearMember();
    });
};

const fnProfile = () => {
    router.push(`/profile`);
};

const TabClick = (item: any) => {
    switch (item.title) {
        case "GN2Studio":
            window.open('https://www.gn2studio.com');
            break;
        case "MuseStudio":
            window.open('https://muse.gn2studio.com');
            break;
        case "Merrytoktok":
            window.open('https://www.merrytoktok.com');
            break;
    }
}
</script>