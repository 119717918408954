<template>
  <Container :key="`mainpage_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <Head :title="t(`common.${pageinfo.master.title}`)" :slug="pageinfo.slug || ''" :key="`head_${pageinfo.key}`" />
    <div class="w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800">
      <h3 class="text-lg text-gray-800 dark:text-gray-100">{{ t(`common.${pageinfo.master.title}`) }}</h3>
    </div>
    <div class="w-full mt-2 py-2">
      <div class="dark:text-gray-300">
        <DataGrid :items="pageinfo.data" :rowclick="fnView">
            <template #columns>
              <th class="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">NO</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Title</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">Read</th>
              <th class="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center">RegistDate</th>
            </template>
            <template #items="{ item, index }">
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-center">{{ (pageinfo.totalCount - index) }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.title }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center">{{ item.readCount }}</td>
              <td class="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400 text-center">{{ dayjs.utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm') }}</td>
            </template>
        </DataGrid>
      </div>
      <div class="w-full mt-1 pt-1 flex justify-between">
        <div class="flex justify-start space-x-2">
          <StyleButton size="sm" class="rounded-md shadow-md px-3 pb-0.5 pt-1" @click="fnNew">새글</StyleButton>
          <StyleButton size="sm" class="rounded-md shadow-md px-3 pb-0.5 pt-1" @click="fnCategory">카테고리 관리리</StyleButton>
        </div>
        <Pagination :cur-page="pageinfo.paging.curPage" :page-size="pageinfo.paging.pageSize" :total-count="pageinfo.totalCount" :callback="fnPaging" class="overflow-hidden rounded-md" />
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { onMounted,ref,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, PagingParameter,BoardContent, BoardMaster } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();

var boardRep = new BoardRepository(config.apis.board, '', '');
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    slug:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:[] as BoardContent[],
    master:new BoardMaster()
});

onMounted(async () => {
  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : '';
  pageinfo.value.slug = (route.params.slug !== null && route.params.slug !== undefined) ? String(route.params.slug) : '';

  boardRep = new BoardRepository(config.apis.board, '', pageinfo.value.slug);

  await getMaster();
  await getBoards();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

watch(
  () => route.params, // params 전체를 감시
  async (newParams, oldParams) => {
    console.log('newParams : ', newParams);

    site.setValue('loading', true);
    pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : '';
    pageinfo.value.slug = (route.params.slug !== null && route.params.slug !== undefined) ? String(route.params.slug) : '';

    boardRep = new BoardRepository(config.apis.board, '', pageinfo.value.slug);

    await getMaster();
    await getBoards();

    pageinfo.value.key += 1;

    setTimeout(() => {
      site.setValue('loading', false);
    }, 200);
  },
  { deep: true } // 객체 내부를 깊게 감시
);

const getBoards = async () => {
  let rst = await boardRep.GetBoards(pageinfo.value.masterCode, pageinfo.value.paging);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    let rst = await boardRep.GetMaster(pageinfo.value.masterCode);
    console.log('getMaster : ', rst);
    if (rst.check) {
      pageinfo.value.master = rst.data;
      pageinfo.value.totalCount = rst.code;
    }
}

const fnPaging = async (num:number) => {
  pageinfo.value.paging.curPage = num;
  await getBoards();
  pageinfo.value.key += 1;
}

const fnView = (item:BoardContent) => {
  console.log(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/${item.id}`);
  router.push(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/${item.id}`);
}

const fnNew = () => {
  router.push(`/board/${pageinfo.value.slug}/${pageinfo.value.masterCode}/edit`);
}

const fnCategory = () => {
  router.push(`/board/category/${pageinfo.value.slug}/${pageinfo.value.masterCode}`);
}
</script>
