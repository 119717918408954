<template>
  <div class="min-h-screen h-full text-gray-800 dark:text-gray-200" :key="pageinfo.key" v-if="pageinfo.isLoaded">
    <ProfileSection :member="pageinfo.member" class="mt-5">
      <StyleButton @click="editProfile" class="mt-2 px-4 py-1" size="lg" type="default" v-if="pageinfo.isLoggedIn && pageinfo.member.id === pageinfo.user.id">
        Edit Profile
      </StyleButton>
    </ProfileSection>

    <Container class="mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <div>
        <div class="mb-6" v-if="pageinfo.myMemberShip !== null && pageinfo.myMemberShip !== undefined && String(pageinfo.myMemberShip.id).trim() !== ''">
          <h2 class="text-2xl font-bold mb-4">{{t('common.CurrentMembership')}}</h2>
          <p class="text-gray-700"><b class="text-lg text-pink-700">{{ pageinfo.myMemberShip.memberShip?.title }}</b> {{t('common.membership')}}</p>
          <p class="text-gray-500">{{t('common.ExpiresOn')}}: {{ dayjs.utc(pageinfo.myMemberShip.expiredDate).local().format('YYYY-MM-DD HH:mm') }}</p>
        </div>
        <div class="mb-6" v-else>
          <h2 class="text-2xl font-bold mb-4">{{t('common.CurrentMembership')}}</h2>
          <p class="text-gray-700">{{t('common.Nohavemembership')}}</p>
          <p class="text-gray-500"><a href="#">{{t('common.Paymembership')}}</a></p>
        </div>
        <div class="mt-4">
          <h2 class="text-2xl font-bold mb-4">{{t('common.PurchaseHistory')}}</h2>
          <DataGrid :items="pageinfo.myHistorian">
              <template #columns>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.membership')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.Price')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ t('common.OrderNo') }}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.RegistDate')}}</th>
                <th class="py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider">{{t('common.PaymentCancel')}}</th>
              </template>
              <template #items="{ item, index }">
                <td class="py-2 px-4 border-b border-gray-200">{{ item.goodsName }}</td>
                <td class="py-2 px-4 border-b border-gray-200">￦{{ StringHelper.formatNumberWithCommas(item.amount) }}</td>
                <td class="py-2 px-4 border-b border-gray-200">{{ item.orderId }}</td>
                <td class="py-2 px-4 border-b border-gray-200">{{ dayjs.utc(item.paidAt).local().format('YYYY-MM-DD HH:mm') }}</td>
                <td class="py-2 px-4 border-b border-gray-200">
                  <button class="py-1 px-2 bg-orange-400 text-white text-mini" @click="fnCancel(item)" v-if="item.status !== null && item.status !== undefined && String(item.status).trim() === 'paid'">{{t('common.PaymentCancel')}}</button>
                  <span class="text-red-500 text-sm" v-if="item.status !== null && item.status !== undefined && String(item.status).trim() === 'cancel'">{{ t('common.CancelComplete') }}</span>
                </td>
              </template>
          </DataGrid>
        </div>
        <div class="py-2 flex justify-center items-center">
            <Pagination :totalCount="pageinfo.totalcount" :curPage="pageinfo.curpage" size="md" :page-size="10" :callback="fnPaging" />
        </div>
      </div>
    </Container>
  </div>
</template>

<script setup lang="ts">
  import { 
    Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper,MyMemberShip,PaymentHistory,StringHelper
    ,MemberShip
   } from '@gn2studio/gn2.library.common';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMemberStore,useGlobalStore } from '@/store';
  import { AuthService } from '@/services';
  import { ProfileSection } from '@/components';
  import { StyleButton,Pagination,Container,DataGrid } from '@gn2studio/gn2components';
  import config from '@/config';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { useI18n } from 'vue-i18n';
  import { MessageBox } from '@/utility';
  
  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const route = useRoute();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const site = useGlobalStore();
  const validate = new ValidateHelper();
  const { t, locale } = useI18n();

  var memberRep = new MemberRepository(config.apis.member, '');
  var contentRep = new ContentRepository(config.apis.content, '');
  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var pageinfo = ref({
    key:0,
    isLoggedIn:false,
    isLoaded:false,
    accessToken:'',
    id:'',
    member: new Member(),
    user: new Member(),
    contents:[] as Content[],
    memberships:[] as MemberShip[],
    Free : new MemberShip(), 
    Premium : new MemberShip(), 
    Enterprice : new MemberShip(), 
    myMemberShip : new MyMemberShip(),
    myHistorian : [] as PaymentHistory[],
    files:[] as Resource[],
    totalcount:0,
    curpage:1,
  });


  onMounted(async () => {
    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.user = memberStore.member;
        pageinfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, token);
        contentRep = new ContentRepository(config.apis.article, token);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
    }

    if (route.params?.id !== null && route.params?.id !== undefined && String(route.params.id).trim() !== '') {
        pageinfo.value.id = String(route.params.id).trim();
        let rst = await memberRep.GetUserInfo(pageinfo.value.id);
        if (rst.check) {
            pageinfo.value.member = rst.data;
        } else {
            router.push(`/error/404`);
        }
    } else {
        if (pageinfo.value.isLoggedIn) {
            pageinfo.value.member = pageinfo.value.user;
        } else {
            router.push(`/error/404`);
        }
    }

    await GetMemberShips();
    await GetMyMemberShip();
    await fnPaging(1);

    setTimeout(() => {
      pageinfo.value.isLoaded = true;
      site.setValue('loading', false);
    }, 100);
  });

  const editProfile = () => {
      router.push(`/profile/edit`);
  }

  const GetMemberShips = async () => {
    let rst = await memberRep.GetMemberShips({ curPage : 1, pageSize : 10, keyword:'' });
    if (rst.check && rst.data !== null) {
      pageinfo.value.memberships = rst.data;
      pageinfo.value.Free = rst.data.filter((x:any) => x.title === "Basic")[0];
      pageinfo.value.Premium = rst.data.filter((x:any) => x.title === "Premium")[0];
      pageinfo.value.Enterprice = rst.data.filter((x:any) => x.title === "Professional")[0];
    }
  }

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    //console.log('GetMyMemberShip : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      
    }
  }

  const fnPaging = async (curpage:number) => {
    let rst = await memberRep.GetHistory({ curPage : curpage, pageSize : 10, keyword:'' });
    //console.log('GetMyMemberShips : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.curpage = curpage;
      pageinfo.value.myHistorian = rst.data;
    }
  };

  const fnCancel = async (log:PaymentHistory) => {
    MessageBox.Confirm(t('site.cancelPaymentMent'), async () => {
      let rst = await memberRep.PutNicePayCancel(log.id);
      if (rst.check) {
        MessageBox.Success(t('common.paymentCancelled'), () => {
          log.status = "cancel";
        });
      }
    });
  };
</script>
