import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from 'vue';
import { Globalizer } from '@gn2studio/gn2.library.common';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  Globalizer.Init();
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})