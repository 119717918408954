import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-xl dark:text-gray-100 font-semibold" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "pl-2 pt-1" }
const _hoisted_4 = { class: "w-[20px] pr-2" }
const _hoisted_5 = ["onClick"]

import { onMounted,ref,computed,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton,Container,InputBox, DataGrid, ButtonInput } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { LanguageData, Member, ValidateHelper, TranslationRepository, SiteRepository, DynamicSite } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'Form',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var tranRep = new TranslationRepository(config.apis.translation, '', slug.value);
var siteRep = new SiteRepository(config.apis.site, '');

var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    category:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    languages:[] as LanguageData[],
    keywords:[] as string[],
    siteinfo:new DynamicSite()
});

onMounted(async () => {
  memberStore.loadFromLocalStorage();
  try {
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
      pageinfo.value.accessToken = token;
      pageinfo.value.member = memberStore.member;
      tranRep = new TranslationRepository(config.apis.translation, pageinfo.value.accessToken, slug.value);
      pageinfo.value.isLoggedIn = true;
    }

    await getSite();
    await getLanguages();
  } catch (e:any) {
    MessageBox.Alert(e.message);
  } finally {
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
      site.setValue('loading', false);
    }, 200);    
  }
});

watch(() => slug.value,
    async (newValue) => {
        pageinfo.value.isLoaded = false;
        await getSite();
        await getLanguages();
        pageinfo.value.key += 1;
        pageinfo.value.isLoaded = true;
    }
);

const getSite = async () => {
  try {
    console.log('slug.value : ', slug.value);
    let rst = await siteRep.GetSiteDetail(slug.value);
    console.log('getSite : ', rst);
    if (rst.check) {
      pageinfo.value.siteinfo = rst.data;
    }
  } catch (e:any) {
    MessageBox.Alert(e.message);
  }
};

const getLanguages = async () => {
  try {
    let rst = await tranRep.GetLanguages();
    if (rst.check) {
      pageinfo.value.languages = rst.data;
      if (pageinfo.value.languages !== null && pageinfo.value.languages !== undefined && pageinfo.value.languages.length > 0) {
          for(let i = 0; i < pageinfo.value.languages.length; i++) {
              pageinfo.value.languages[i].data = await getData(pageinfo.value.languages[i].languageCode);
          }

          if (pageinfo.value.languages[0].data !== null && pageinfo.value.languages[0].data?.site !== undefined && pageinfo.value.languages[0].data?.site !== null) {
            pageinfo.value.keywords = Object.keys(pageinfo.value.languages[0].data.site);
          }
      }
    } else {
      MessageBox.Alert(rst.message);
    }
  } catch (e:any) {
    MessageBox.Alert(e.message);
  }
};

const getData = async (languageCode:string) => {
    let result = [] as any[];
    let rst = await tranRep.GetTranslations(languageCode, config.global.version);
    if (rst.check) {
       result = rst.data;
    }
    return result;
}

const fnSave = async (content:string, jsonData:any) => {
  jsonData["content"] = content;
  jsonData["siteSlug"] = slug.value;
  jsonData["mode"] = 'site';

  let rst = await tranRep.SaveTranslation(jsonData);
  if (rst.check) {
    MessageBox.Success(t('system.Saved'));
  } else {
    MessageBox.Alert(rst.message);
  }
}

const fnAppend = () => {
  MessageBox.Console('추가할 키워드를 입력하세요.', (keyword:string) => {
    pageinfo.value.keywords.push(keyword);
  });
}

const fnRemove = (slugstr:string, index:number) => {
  MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
    pageinfo.value.keywords.splice(index, 1);

    let jsonData = {} as any;
    jsonData["slug"] = slugstr;
    jsonData["siteSlug"] = slug.value;
    jsonData["mode"] = 'site';

    let rst = await tranRep.DeleteTranslation(jsonData);
    if (rst.check) {
      MessageBox.Success(t('system.Deleted'));
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: 0,
        class: "p-5"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", _hoisted_1, _toDisplayString(_unref(pageinfo).siteinfo.name) + " 번역관리 툴", 1),
          _createVNode(_unref(DataGrid), {
            items: _unref(pageinfo).keywords
          }, {
            columns: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("th", {
                class: "text-gray-200 bg-gray-900 py-3",
                style: {"width":"120px"}
              }, "keyword", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageinfo).languages, (translation, index) => {
                return (_openBlock(), _createElementBlock("th", {
                  class: "text-gray-200 bg-gray-900 py-3",
                  key: `column_${index}`
                }, _toDisplayString(translation.nativeName), 1))
              }), 128))
            ]),
            items: _withCtx(({ item, index }) => [
              _createElementVNode("td", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(item), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("button", {
                    class: "pt-1 px-2 text-center",
                    onClick: ($event: any) => (fnRemove(item, index))
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("i", { class: "fa-solid fa-trash-alt text-xs" }, null, -1)
                  ]), 8, _hoisted_5)
                ])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pageinfo).languages, (data, j) => {
                return (_openBlock(), _createElementBlock("td", {
                  class: "",
                  key: `data_${index}_${j}`
                }, [
                  _createVNode(_unref(ButtonInput), {
                    icon: "fa-solid fa-floppy-disk",
                    divclass: "w-full",
                    class: "w-full p-1",
                    "is-loading": false,
                    modelValue: data.data.site[item],
                    "onUpdate:modelValue": ($event: any) => ((data.data.site[item]) = $event),
                    onclick: fnSave,
                    prefix: { slug : item, languageCode : data.data.languageCode }
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "prefix"])
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["items"]),
          _createVNode(_unref(StyleButton), {
            class: "mt-5 py-2 px-3",
            icon: "fa-solid fa-plus",
            onclick: fnAppend
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("번역추가")
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})