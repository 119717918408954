<template>
<Container class="p-8">
    <div class="">
    <!-- Header -->
    <div class="bg-gray-600 text-white text-center py-6 rounded-md">
        <h3 class="text-2xl font-bold">Member Information</h3>
    </div>

    <div class="p-6 space-y-6 text-gray-700 dark:text-white">
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Email:</span>
        <span class="">{{ pageinfo.data.email }}</span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Email Confirmed:</span>
        <span class="">
            <BooleanBadge :check="pageinfo.data.emailConfirmed ?? false" />
        </span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">ID:</span>
        <span class="">{{ pageinfo.data.id }}</span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Username:</span>
        <span class="">{{ pageinfo.data.userName }}</span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Phone Number:</span>
        <span class="">{{ pageinfo.data.phoneNumber }}</span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Phone Number Confirmed:</span>
        <span class="">
            <BooleanBadge :check="pageinfo.data.phoneNumberConfirmed ?? false" />
        </span>
        </div>
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Registration Date:</span>
        <span class="">{{ pageinfo.data.registDate ? dayjs.utc(pageinfo.data.registDate).local().format('YYYY-MM-DD HH:mm') : '' }}</span>
        </div>
        <!-- Status -->
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">Status:</span>
        <span class="">{{ pageinfo.data.status }}</span>
        </div>
        <!-- User Type -->
        <div class="flex items-center justify-between border-b pb-4">
        <span class="font-medium">User Type:</span>
        <span class="">{{ pageinfo.data.type }}</span>
        </div>
    </div>

    <!-- Footer -->
    <div class="flex justify-end p-6">
        <StyleButton class="ml-2 py-2 px-4" @click="fnList">List</StyleButton>
    </div>
    </div>
</Container>
</template>

<script setup lang="ts">
import { computed, onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid, BooleanBadge } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { MemberRepository, PagingParameter,Member, V_Member } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const Id = computed(() => route.params.id);

var memberRep = new MemberRepository(config.apis.member, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:new V_Member(),
    member:new Member()
});

onMounted(async () => {
    if (Id.value === null || Id.value === undefined || String(Id.value).trim() === '') {
        router.push(`/error/500`);
    }

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.member = memberStore.member;
        memberRep = new MemberRepository(config.apis.member, pageinfo.value.accessToken);
    }

    await getMember();

    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 200);
});

const getMember = async () => {
  let rst = await memberRep.GetMemberDetail(String(Id.value));
  if (rst.check) {
    pageinfo.value.data = rst.data;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnList = async () => {
    router.push(`/member/list`);
}
</script>
