import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-gray-300 py-4 text-center" }

import { onMounted, ref } from 'vue';
import { Container } from '@gn2studio/gn2components';
import { ValidateHelper,Categories } from '@gn2studio/gn2.library.common';
import { useCategoryStore } from '@/store';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Footer',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const validate = new ValidateHelper();
const categoryStore = useCategoryStore();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:'',
    categories:[] as Categories[],
});

onMounted(async () => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';

    await fnCategories();
});

const fnCategories = async () => {
  componentInfo.value.categories = await categoryStore.getCategories();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", {
    class: _normalizeClass(["bg-slate-900", _unref(componentInfo).class])
  }, [
    _createVNode(_unref(Container), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createTextVNode("©" + _toDisplayString(_unref(componentInfo).year) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", null, "gn2studio.com", -1)),
          _cache[1] || (_cache[1] = _createTextVNode(". All rights reserved."))
        ])
      ]),
      _: 1
    })
  ], 2))
}
}

})