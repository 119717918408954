<template>
<StyleButton :class="componentInfo.class" :size="componentInfo.size" v-if="componentInfo.isLoaded" :key="`ProfileButton_${componentInfo.key}`">
    <div class="flex justify-start space-x-1">
        <CircleImage :src="componentInfo.member?.photo" width="20px" height="20px" :border="1" />
        <span class="hidden lg:block font-normal mt-0.5">{{ componentInfo.member?.nickName }}</span>
    </div>
</StyleButton>
</template>

<script setup lang="ts">
import { Member } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { CircleImage } from '../molecules';
import { StyleButton } from '@gn2studio/gn2components';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    member?:Member|null|undefined
};

const property = defineProps<containerParameter>();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'sm',
    member:new Member()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
    componentInfo.value.member = property.member ?? new Member();
    componentInfo.value.isLoaded = true;
});
</script>