import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full lg:w-1/2 mt-6 p-6 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg" }
const _hoisted_2 = { class: "flex flex-col gap-4" }
const _hoisted_3 = { class: "w-full lg:w-1/2 mt-6 p-6 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg" }
const _hoisted_4 = { class: "flex flex-col gap-4" }

import { Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper,IdentityRepository } from '@gn2studio/gn2.library.common';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMemberStore,useGlobalStore } from '@/store';
  import { AuthService } from '@/services';
  import { ProfileSection } from '@/components';
  import { StyleButton,InputBox,TextBox,Container } from '@gn2studio/gn2components';
  import config from '@/config';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { MessageBox } from '@/utility';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'EditView',
  setup(__props) {

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const route = useRoute();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const site = useGlobalStore();
  const validate = new ValidateHelper();

  var memberRep = new MemberRepository(config.apis.member, '');
  var contentRep = new ContentRepository(config.apis.content, '');
  var identityRep = new IdentityRepository(config.identity.api, '');
  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var pageinfo = ref({
      key:0,
      isLoggedIn:false,
      accessToken:'',
      id:'',
      member: new Member(),
      contents:[] as Content[],
      files:[] as Resource[],
      password: {
          oldpassword:'',
          newpassword:'',
          confirmpassword:''
      }
  });


  onMounted(async () => {
      memberStore.loadFromLocalStorage();
      let token = await auth.getAccessToken();
      if (token !== null && token !== undefined && String(token).trim() !== '') {
          pageinfo.value.accessToken = token;
          pageinfo.value.member = memberStore.member;
          pageinfo.value.isLoggedIn = true;
          memberRep = new MemberRepository(config.apis.member, token);
          contentRep = new ContentRepository(config.apis.content, token);
          fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
          identityRep = new IdentityRepository(config.identity.api, token);
      }

      setTimeout(() => {
          site.setValue('loading', false);
      }, 100);
  });

  const editProfile = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.member.nickName)) {
      MessageBox.Alert('닉네임을 입력하세요.');
      return;
    }

    let rst = await memberRep.UpdateMemberInfo(pageinfo.value.member);
    if (rst.check) {
        MessageBox.Success('저장했습니다.', () => {
          memberStore.setMember(pageinfo.value.member);
        });
    } else {
        MessageBox.Alert(rst.message);
    }
  }

  const onPasswordClear = () => {
    pageinfo.value.password.oldpassword = '';
    pageinfo.value.password.newpassword = '';
    pageinfo.value.password.confirmpassword = '';
  };

  const editPassword = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.oldpassword)) {
      MessageBox.Alert('현재 비밀번호를 입력하세요.');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.newpassword)) {
      MessageBox.Alert('새 비밀번호를 입력하세요.');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.confirmpassword)) {
      MessageBox.Alert('비밀번호 확인을 입력하세요.');
      return;
    }

    let rst = await identityRep.PutPasswordChange(pageinfo.value.password.oldpassword, pageinfo.value.password.newpassword);
    if (rst.check) {
        MessageBox.Success('비밀번호가 변경되었습니다.', () => {
          onPasswordClear();
        });
    } else {
        MessageBox.Alert(rst.message);
    }
  }

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoggedIn)
    ? (_openBlock(), _createElementBlock("div", {
        class: "min-h-screen text-gray-800 dark:text-gray-200",
        key: _unref(pageinfo).key
      }, [
        _createVNode(_unref(ProfileSection), {
          member: _unref(pageinfo).member
        }, null, 8, ["member"]),
        _createVNode(_unref(Container), { class: "lg:flex lg:justify-between lg:space-x-2 px-2" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray-500 dark:text-gray-400 text-sm" }, "닉네임", -1)),
                  _createVNode(_unref(InputBox), {
                    class: "text-xm mt-1 text-left rounded-md w-full",
                    type: "text",
                    modelValue: _unref(pageinfo).member.nickName,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(pageinfo).member.nickName) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-gray-500 dark:text-gray-400 text-sm" }, "Bio", -1)),
                  _createVNode(_unref(TextBox), {
                    class: "text-xm mt-1 text-left rounded-md w-full h-[120px]",
                    type: "text",
                    modelValue: _unref(pageinfo).member.bio,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(pageinfo).member.bio) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_unref(StyleButton), {
                onClick: editProfile,
                class: "mt-4 px-4 py-1",
                size: "lg"
              }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" 정보변경 ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-gray-500 dark:text-gray-400 text-sm" }, "Password", -1)),
                  _createVNode(_unref(InputBox), {
                    class: "text-2xl font-semibold mt-1 text-left rounded-md w-full",
                    type: "password",
                    modelValue: _unref(pageinfo).password.oldpassword,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(pageinfo).password.oldpassword) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-gray-500 dark:text-gray-400 text-sm" }, "New Password", -1)),
                  _createVNode(_unref(InputBox), {
                    class: "text-2xl font-semibold mt-1 text-left rounded-md w-full",
                    type: "password",
                    modelValue: _unref(pageinfo).password.newpassword,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(pageinfo).password.newpassword) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-gray-500 dark:text-gray-400 text-sm" }, "Confirm Password", -1)),
                  _createVNode(_unref(InputBox), {
                    class: "text-2xl font-semibold mt-1 text-left rounded-md w-full",
                    type: "password",
                    modelValue: _unref(pageinfo).password.confirmpassword,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(pageinfo).password.confirmpassword) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createVNode(_unref(StyleButton), {
                onClick: editPassword,
                class: "mt-4 px-4 py-1",
                size: "lg"
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" 비밀번호 변경 ")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})