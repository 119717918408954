import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref, watch } from 'vue';
import { StyleButton } from '@gn2studio/gn2components';

interface buttonParameter {
    isStatus:boolean,
    class?:string|null,
    size?:string|null,
    enableEvent:Function|null|undefined,
    disableEvent:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleButton',
  props: {
    isStatus: { type: Boolean },
    class: {},
    size: {},
    enableEvent: { type: [Function, null] },
    disableEvent: { type: [Function, null] }
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    isLoaded:false,
    isStatus:false,
    class:'',
    size:'',
    enableEvent:null as Function|null|undefined,
    disableEvent:null as Function|null|undefined
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'md';
    componentInfo.value.isStatus = property.isStatus;
    componentInfo.value.enableEvent = property.enableEvent;
    componentInfo.value.disableEvent = property.disableEvent;
    componentInfo.value.isLoaded = true;
});

watch(() => property,
    (newValue) => {
        componentInfo.value.size = (newValue.size !== null && newValue.size !== undefined) ? newValue.size : '';
        componentInfo.value.class = (newValue.class !== null && newValue.class !== undefined) ? newValue.class : '';
        componentInfo.value.isStatus = (newValue.isStatus !== null && newValue.isStatus !== undefined) ? newValue.isStatus : false;
        componentInfo.value.enableEvent = (newValue.enableEvent !== null && newValue.enableEvent !== undefined) ? newValue.enableEvent : null;
        componentInfo.value.disableEvent = (newValue.disableEvent !== null && newValue.disableEvent !== undefined) ? newValue.disableEvent : null;
        componentInfo.value.key += 1;
    }
);


const fnButtonClick = () => {
    componentInfo.value.isStatus = !componentInfo.value.isStatus;
    if (componentInfo.value.isStatus) {
        if (componentInfo.value.enableEvent !== null && componentInfo.value.enableEvent !== undefined && typeof componentInfo.value.enableEvent === "function") {
            componentInfo.value.enableEvent();
            componentInfo.value.key += 1;
        }
    } else {
        if (componentInfo.value.disableEvent !== null && componentInfo.value.disableEvent !== undefined && typeof componentInfo.value.disableEvent === "function") {
            componentInfo.value.disableEvent();
            componentInfo.value.key += 1;
        }
    }
};

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(StyleButton), {
        class: _normalizeClass(_unref(componentInfo).class),
        onClick: fnButtonClick,
        key: `togglebutton_${_unref(componentInfo).key}`,
        size: _unref(componentInfo).size
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["class", "size"]))
    : _createCommentVNode("", true)
}
}

})