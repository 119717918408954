import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full p-3 mb-1" }
const _hoisted_2 = { class: "text-left text-gray-800 border-t border-b border-gray-400 dark:border-gray-700" }
const _hoisted_3 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_4 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_5 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_6 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_7 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_8 = { class: "dark:text-gray-200" }
const _hoisted_9 = { class: "text-left text-gray-800 border-t border-b border-gray-400 dark:border-gray-700" }
const _hoisted_10 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_11 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_12 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_13 = { class: "w-full p-3 mb-1" }
const _hoisted_14 = { class: "text-left text-gray-200 border-t border-b border-gray-400 dark:border-gray-700" }
const _hoisted_15 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_16 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_17 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_18 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }
const _hoisted_19 = { class: "border-b border-dashed border-gray-300 dark:border-gray-800 last-hide" }

import { onMounted,ref } from 'vue';
import { BoardMaster,LinkItem,BoardRepository,Member,MemberRepository,DynamicSite,SiteRepository,TranslationRepository } from "@gn2studio/gn2.library.common";
import { useMemberStore } from '@/store';
import { Accordion, Skeleton } from '@gn2studio/gn2components';
import { AuthService } from '@/services';
import config from '@/config';
import router from '@/router';
import { useI18n } from 'vue-i18n';

interface navParameter {
    class?:string|null
}
export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  props: {
    class: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const memberStore = useMemberStore();

const auth = new AuthService();
var boardRep = new BoardRepository(config.apis.board, '', '');
var memberRep = new MemberRepository(config.apis.member, '');
var siteRep = new SiteRepository(config.apis.site, '');
var transRep = new TranslationRepository(config.apis.translation, '', '');
var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    accessToken:'', 
    member:new Member(),
    sites:[] as DynamicSite[],
    masters:[] as BoardMaster[],
    menus: {
        studio:[] as LinkItem[],
        Management:[] as LinkItem[],
        muse:[] as LinkItem[],
        merrytoktok:[] as LinkItem[],
    },
    languages: {
        studio:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        Management:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        muse:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        merrytoktok:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
    },
    contents:[
        { title : '도서관리', url : '/book/list', active : false, type: 'default' },
        { title : '작가관리', url : '#', active : false, type: 'default' },
        { title : '리뷰관리', url : '#', active : false, type: 'default' },
        { title : '메모관리', url : '#', active : false, type: 'default' }
    ] as LinkItem[],
    payment:[
        { title : '결제내역관리', url : '#', active : false, type: 'default' },
        { title : '상품관리', url : '#', active : false, type: 'default' }
    ] as LinkItem[],
    isLoggedIn:false
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        componentInfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, componentInfo.value.accessToken);
        transRep = new TranslationRepository(config.apis.translation, componentInfo.value.accessToken, '');
        siteRep = new SiteRepository(config.apis.site, componentInfo.value.accessToken);
    }

    await fnSite();

    componentInfo.value.isLoaded = true;
});

const fnMasters = async (slug:string) => {
    let result = [] as LinkItem[];
    switch (slug) {
        case 'studio':
        case 'muse':
        case 'merrytoktok':
            boardRep = new BoardRepository(config.apis.board, componentInfo.value.accessToken, slug);
            let rst = await boardRep.GetMasters({ curPage : 1, pageSize : 50, keyword : '' });
            if (rst.check) {
                for (let i = 0; i < rst.data.length; i++) {
                    result.push({ 
                        title : t(`common.${rst.data[i].title}`), 
                        url : rst.data[i].masterCode, 
                        active : false, 
                        type: 'default' 
                    });
                }
            }
            break;
    }
    return result;
}

const fnSite = async () => {
    let rst = await siteRep.GetSites();
    if (rst.check) {
        componentInfo.value.sites = rst.data;
        for (let i = 0; i < componentInfo.value.sites.length; i++) {
            let site = componentInfo.value.sites[i];
            if (site !== null && site.slug !== null) {
                let bds = await fnMasters(site.slug);
                switch (site.slug) {
                    case 'studio':
                    case 'muse':
                    case 'merrytoktok':
                        componentInfo.value.menus[site.slug] = bds;
                        break;
                }
            }
        }
    }
};

const fnBoardLink = (masterCode:string, slug:string|null|undefined) => {
    if (slug === null || slug === undefined) return;
    router.push(`/board/${slug}/${masterCode}`);
    componentInfo.value.key += 1;
}

const fnTransLink = (languageCode:string, slug:string|null|undefined) => {
    if (slug === null || slug === undefined) return;
    router.push(`/translation/form/${slug}`);
    componentInfo.value.key += 1;
}

const fnSiteArray = (slug:string) => {
    switch (slug) {
        case 'studio':
            return componentInfo.value.menus.studio;
        case 'muse':
            return componentInfo.value.menus.muse;
        case 'merrytoktok':
            return componentInfo.value.menus.merrytoktok;
        default:
            return [];
    }
}


const fnLanguageArray = (slug:string) => {
    switch (slug) {
        case 'studio':
            return componentInfo.value.languages.studio;
        case 'muse':
            return componentInfo.value.languages.muse;
        case 'merrytoktok':
            return componentInfo.value.languages.merrytoktok;
        default:
            return [];
    }
}

const fnLink = (url:string) => {
    //router.push(url);
    document.location.href = url;
    componentInfo.value.key += 1;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full", _unref(componentInfo).class || '']),
        key: `navbar-${_unref(componentInfo).key}`
      }, [
        _createElementVNode("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "dark:text-gray-200" }, "통합설정", -1)),
          _createElementVNode("ul", _hoisted_2, [
            _createElementVNode("li", _hoisted_3, [
              _createVNode(_component_router_link, {
                class: "border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5",
                to: "/member/list"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("회원관리")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_4, [
              _createVNode(_component_router_link, {
                class: "border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5",
                to: "#"
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("번역그룹관리")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", _hoisted_5, [
              (_openBlock(), _createBlock(_unref(Accordion), {
                items: _unref(componentInfo).contents,
                "link-func": fnLink,
                title: `컨텐츠관리`,
                key: `content-${_unref(componentInfo).key}`
              }, null, 8, ["items"]))
            ]),
            _createElementVNode("li", _hoisted_6, [
              (_openBlock(), _createBlock(_unref(Accordion), {
                items: _unref(componentInfo).payment,
                "link-func": fnLink,
                title: `결제관리`,
                key: `payment-${_unref(componentInfo).key}`
              }, null, 8, ["items"]))
            ]),
            _createElementVNode("li", _hoisted_7, [
              _createVNode(_component_router_link, {
                class: "border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5",
                to: "#"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("사이트관리")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(componentInfo).sites.filter(x => x.slug !== 'Management'), (site) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "w-full p-3 mb-1",
            key: `site-${site.id}`
          }, [
            _createElementVNode("h3", _hoisted_8, _toDisplayString(site.name), 1),
            _createElementVNode("ul", _hoisted_9, [
              _createElementVNode("li", _hoisted_10, [
                _createVNode(_component_router_link, {
                  class: "border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5",
                  to: `/board/set/${site.slug}`
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("게시판 관리")
                  ])),
                  _: 2
                }, 1032, ["to"])
              ]),
              _createElementVNode("li", _hoisted_11, [
                (_openBlock(), _createBlock(_unref(Accordion), {
                  items: fnSiteArray(site.slug),
                  "link-func": fnBoardLink,
                  prefix: site.slug,
                  title: `게시판 관리`,
                  key: `board-${_unref(componentInfo).key}`
                }, null, 8, ["items", "prefix"]))
              ]),
              _createElementVNode("li", _hoisted_12, [
                (_openBlock(), _createBlock(_unref(Accordion), {
                  items: fnLanguageArray(site.slug),
                  "link-func": fnTransLink,
                  prefix: site.slug,
                  title: `번역 관리`,
                  key: `translation-${_unref(componentInfo).key}`
                }, null, 8, ["items", "prefix"]))
              ])
            ])
          ]))
        }), 128))
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["w-full", _unref(componentInfo).class || ''])
      }, [
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_unref(Skeleton), { class: "w-[100px] h-[25px] my-2" }),
          _createElementVNode("ul", _hoisted_14, [
            _createElementVNode("li", _hoisted_15, [
              _createVNode(_unref(Skeleton), { class: "w-[90%] h-[22px] my-2" })
            ]),
            _createElementVNode("li", _hoisted_16, [
              _createVNode(_unref(Skeleton), { class: "w-[90%] h-[22px] my-2" })
            ]),
            _createElementVNode("li", _hoisted_17, [
              _createVNode(_unref(Skeleton), { class: "w-[90%] h-[22px] my-2" })
            ]),
            _createElementVNode("li", _hoisted_18, [
              _createVNode(_unref(Skeleton), { class: "w-[90%] h-[22px] my-2" })
            ]),
            _createElementVNode("li", _hoisted_19, [
              _createVNode(_unref(Skeleton), { class: "w-[90%] h-[22px] my-2" })
            ])
          ])
        ])
      ], 2))
}
}

})