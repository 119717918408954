<template>
    <Container class="p-5" v-if="pageinfo.isLoaded">
      <h3 class="text-xl dark:text-gray-100 font-semibold">{{ pageinfo.siteinfo.name }} 번역관리 툴</h3>
      <DataGrid :items="pageinfo.keywords">
          <template #columns>
              <th class="text-gray-200 bg-gray-900 py-3" style="width:120px;">keyword</th>
              <th class="text-gray-200 bg-gray-900 py-3" v-for="(translation, index) in pageinfo.languages" :key="`column_${index}`">
                {{ translation.nativeName }}
              </th>
          </template>
          <template #items="{ item, index }">
              <td class="flex justify-between">
                <div class="pl-2 pt-1">{{ item }}</div>
                <div class="w-[20px] pr-2">
                  <button class="pt-1 px-2 text-center" @click="fnRemove(item, index)">
                    <i class="fa-solid fa-trash-alt text-xs"></i>
                  </button>
                </div>
              </td>
              <td class="" v-for="(data, j) in pageinfo.languages" :key="`data_${index}_${j}`">
                  <ButtonInput icon="fa-solid fa-floppy-disk" divclass="w-full" class="w-full p-1" :is-loading="false" v-model="data.data.site[item]" :onclick="fnSave" :prefix="{ slug : item, languageCode : data.data.languageCode }" />
              </td>
          </template>
      </DataGrid>

      <StyleButton class="mt-5 py-2 px-3" icon="fa-solid fa-plus" :onclick="fnAppend">번역추가</StyleButton>
    </Container>
</template>

<script setup lang="ts">
import { onMounted,ref,computed,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton,Container,InputBox, DataGrid, ButtonInput } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { LanguageData, Member, ValidateHelper, TranslationRepository, SiteRepository, DynamicSite } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var tranRep = new TranslationRepository(config.apis.translation, '', slug.value);
var siteRep = new SiteRepository(config.apis.site, '');

var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    category:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    languages:[] as LanguageData[],
    keywords:[] as string[],
    siteinfo:new DynamicSite()
});

onMounted(async () => {
  memberStore.loadFromLocalStorage();
  try {
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
      pageinfo.value.accessToken = token;
      pageinfo.value.member = memberStore.member;
      tranRep = new TranslationRepository(config.apis.translation, pageinfo.value.accessToken, slug.value);
      pageinfo.value.isLoggedIn = true;
    }

    await getSite();
    await getLanguages();
  } catch (e:any) {
    MessageBox.Alert(e.message);
  } finally {
    pageinfo.value.isLoaded = true;

    setTimeout(() => {
      site.setValue('loading', false);
    }, 200);    
  }
});

watch(() => slug.value,
    async (newValue) => {
        pageinfo.value.isLoaded = false;
        await getSite();
        await getLanguages();
        pageinfo.value.key += 1;
        pageinfo.value.isLoaded = true;
    }
);

const getSite = async () => {
  try {
    console.log('slug.value : ', slug.value);
    let rst = await siteRep.GetSiteDetail(slug.value);
    console.log('getSite : ', rst);
    if (rst.check) {
      pageinfo.value.siteinfo = rst.data;
    }
  } catch (e:any) {
    MessageBox.Alert(e.message);
  }
};

const getLanguages = async () => {
  try {
    let rst = await tranRep.GetLanguages();
    if (rst.check) {
      pageinfo.value.languages = rst.data;
      if (pageinfo.value.languages !== null && pageinfo.value.languages !== undefined && pageinfo.value.languages.length > 0) {
          for(let i = 0; i < pageinfo.value.languages.length; i++) {
              pageinfo.value.languages[i].data = await getData(pageinfo.value.languages[i].languageCode);
          }

          if (pageinfo.value.languages[0].data !== null && pageinfo.value.languages[0].data?.site !== undefined && pageinfo.value.languages[0].data?.site !== null) {
            pageinfo.value.keywords = Object.keys(pageinfo.value.languages[0].data.site);
          }
      }
    } else {
      MessageBox.Alert(rst.message);
    }
  } catch (e:any) {
    MessageBox.Alert(e.message);
  }
};

const getData = async (languageCode:string) => {
    let result = [] as any[];
    let rst = await tranRep.GetTranslations(languageCode, config.global.version);
    if (rst.check) {
       result = rst.data;
    }
    return result;
}

const fnSave = async (content:string, jsonData:any) => {
  jsonData["content"] = content;
  jsonData["siteSlug"] = slug.value;
  jsonData["mode"] = 'site';

  let rst = await tranRep.SaveTranslation(jsonData);
  if (rst.check) {
    MessageBox.Success(t('system.Saved'));
  } else {
    MessageBox.Alert(rst.message);
  }
}

const fnAppend = () => {
  MessageBox.Console('추가할 키워드를 입력하세요.', (keyword:string) => {
    pageinfo.value.keywords.push(keyword);
  });
}

const fnRemove = (slugstr:string, index:number) => {
  MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
    pageinfo.value.keywords.splice(index, 1);

    let jsonData = {} as any;
    jsonData["slug"] = slugstr;
    jsonData["siteSlug"] = slug.value;
    jsonData["mode"] = 'site';

    let rst = await tranRep.DeleteTranslation(jsonData);
    if (rst.check) {
      MessageBox.Success(t('system.Deleted'));
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}
</script>
