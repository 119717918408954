<template>
  <footer class="bg-slate-900" :class="componentInfo.class">
    <Container>
      <div class="text-gray-300 py-4 text-center">©{{ componentInfo.year }} <span>gn2studio.com</span>. All rights reserved.</div>
    </Container>
  </footer>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { Container } from '@gn2studio/gn2components';
import { ValidateHelper,Categories } from '@gn2studio/gn2.library.common';
import { useCategoryStore } from '@/store';
import { useI18n } from 'vue-i18n';

interface containerParameter {
    class?:string|null
};

const { t, locale } = useI18n();
const property = defineProps<containerParameter>();
const validate = new ValidateHelper();
const categoryStore = useCategoryStore();

var componentInfo = ref({
    year:0,
    class:'',
    documentUrl:'',
    linkUrl:'',
    categories:[] as Categories[],
});

onMounted(async () => {
    let dt = new Date();
    componentInfo.value.year = dt.getFullYear();
    componentInfo.value.class = property.class ?? '';

    await fnCategories();
});

const fnCategories = async () => {
  componentInfo.value.categories = await categoryStore.getCategories();
};
</script>