<template>
  <div class="min-h-screen text-gray-800 dark:text-gray-200" :key="pageinfo.key" v-if="pageinfo.isLoggedIn">
    <ProfileSection :member="pageinfo.member"></ProfileSection>
    <Container class="lg:flex lg:justify-between lg:space-x-2 px-2">
      <div class="w-full lg:w-1/2 mt-6 p-6 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg">
        <div class="flex flex-col gap-4">
          <div>
            <p class="text-gray-500 dark:text-gray-400 text-sm">닉네임</p>
            <InputBox class="text-xm mt-1 text-left rounded-md w-full" type="text" v-model="pageinfo.member.nickName"></InputBox>
          </div>
          <div>
            <p class="text-gray-500 dark:text-gray-400 text-sm">Bio</p>
            <TextBox class="text-xm mt-1 text-left rounded-md w-full h-[120px]" type="text" v-model="pageinfo.member.bio"></TextBox>
          </div>
        </div>
        <StyleButton @click="editProfile" class="mt-4 px-4 py-1" size="lg">
        정보변경
        </StyleButton>
      </div>
      <div class="w-full lg:w-1/2 mt-6 p-6 bg-gray-200 dark:bg-gray-800 rounded-lg shadow-lg">
        <div class="flex flex-col gap-4">
          <div>
            <p class="text-gray-500 dark:text-gray-400 text-sm">Password</p>
            <InputBox class="text-2xl font-semibold mt-1 text-left rounded-md w-full" type="password" v-model="pageinfo.password.oldpassword"></InputBox>
          </div>
          <div>
            <p class="text-gray-500 dark:text-gray-400 text-sm">New Password</p>
            <InputBox class="text-2xl font-semibold mt-1 text-left rounded-md w-full" type="password" v-model="pageinfo.password.newpassword"></InputBox>
          </div>
          <div>
            <p class="text-gray-500 dark:text-gray-400 text-sm">Confirm Password</p>
            <InputBox class="text-2xl font-semibold mt-1 text-left rounded-md w-full" type="password" v-model="pageinfo.password.confirmpassword"></InputBox>
          </div>
        </div>
        <StyleButton @click="editPassword" class="mt-4 px-4 py-1" size="lg">
        비밀번호 변경
        </StyleButton>
      </div>
    </Container>

  </div>
</template>

<script setup lang="ts">
  import { Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper,IdentityRepository } from '@gn2studio/gn2.library.common';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMemberStore,useGlobalStore } from '@/store';
  import { AuthService } from '@/services';
  import { ProfileSection } from '@/components';
  import { StyleButton,InputBox,TextBox,Container } from '@gn2studio/gn2components';
  import config from '@/config';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { MessageBox } from '@/utility';

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const route = useRoute();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const site = useGlobalStore();
  const validate = new ValidateHelper();

  var memberRep = new MemberRepository(config.apis.member, '');
  var contentRep = new ContentRepository(config.apis.content, '');
  var identityRep = new IdentityRepository(config.identity.api, '');
  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var pageinfo = ref({
      key:0,
      isLoggedIn:false,
      accessToken:'',
      id:'',
      member: new Member(),
      contents:[] as Content[],
      files:[] as Resource[],
      password: {
          oldpassword:'',
          newpassword:'',
          confirmpassword:''
      }
  });


  onMounted(async () => {
      memberStore.loadFromLocalStorage();
      let token = await auth.getAccessToken();
      if (token !== null && token !== undefined && String(token).trim() !== '') {
          pageinfo.value.accessToken = token;
          pageinfo.value.member = memberStore.member;
          pageinfo.value.isLoggedIn = true;
          memberRep = new MemberRepository(config.apis.member, token);
          contentRep = new ContentRepository(config.apis.content, token);
          fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
          identityRep = new IdentityRepository(config.identity.api, token);
      }

      setTimeout(() => {
          site.setValue('loading', false);
      }, 100);
  });

  const editProfile = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.member.nickName)) {
      MessageBox.Alert('닉네임을 입력하세요.');
      return;
    }

    let rst = await memberRep.UpdateMemberInfo(pageinfo.value.member);
    if (rst.check) {
        MessageBox.Success('저장했습니다.', () => {
          memberStore.setMember(pageinfo.value.member);
        });
    } else {
        MessageBox.Alert(rst.message);
    }
  }

  const onPasswordClear = () => {
    pageinfo.value.password.oldpassword = '';
    pageinfo.value.password.newpassword = '';
    pageinfo.value.password.confirmpassword = '';
  };

  const editPassword = async () => {
    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.oldpassword)) {
      MessageBox.Alert('현재 비밀번호를 입력하세요.');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.newpassword)) {
      MessageBox.Alert('새 비밀번호를 입력하세요.');
      return;
    }

    if (validate.IsNullOrWhiteSpace(pageinfo.value.password.confirmpassword)) {
      MessageBox.Alert('비밀번호 확인을 입력하세요.');
      return;
    }

    let rst = await identityRep.PutPasswordChange(pageinfo.value.password.oldpassword, pageinfo.value.password.newpassword);
    if (rst.check) {
        MessageBox.Success('비밀번호가 변경되었습니다.', () => {
          onPasswordClear();
        });
    } else {
        MessageBox.Alert(rst.message);
    }
  }
</script>
