import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-end space-x-2 text-sm items-center" }
const _hoisted_2 = {
  key: 0,
  class: "text-xs"
}

import { computed,onMounted,ref,watch } from 'vue';
import { DynamicSite,Member,MemberRepository,SiteRepository } from "@gn2studio/gn2.library.common";
import { useSiteStore,useMemberStore } from '@/store';
import { AuthService } from '@/services';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

interface navParameter {
    class?:string|null,
    title:string,
    slug:string
}
export default /*@__PURE__*/_defineComponent({
  __name: 'Head',
  props: {
    class: {},
    title: {},
    slug: {}
  },
  setup(__props: any) {

;

const { t, locale } = useI18n();
const property = __props;
const siteStore = useSiteStore();
const site = computed(() => siteStore.getSite());
const memberStore = useMemberStore();
const route = useRoute();

const auth = new AuthService();
var memberRep = new MemberRepository(config.apis.member, '');
var siteRep = new SiteRepository(config.apis.site, '');
var componentInfo = ref({
    key:0,
    isLoaded:false,
    slug: '',
    site: new DynamicSite(),
    accessToken:'', 
    member:new Member(),
    isLoggedIn:false
});

onMounted(async () => {
    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        componentInfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, componentInfo.value.accessToken);
        siteRep = new SiteRepository(config.apis.site, componentInfo.value.accessToken);
    }

    if (site.value !== null && site.value !== undefined) {
        componentInfo.value.site = site.value;
        componentInfo.value.slug = site.value.slug;
        if (componentInfo.value.slug !== null && componentInfo.value.slug !== undefined && String(componentInfo.value.slug).trim() !== '') {
            await fnSite(componentInfo.value.slug);
        }
    }

    componentInfo.value.isLoaded = true;
});

watch([property.slug],
  async ([newSlug], [oldSlug]) => {
    if (newSlug !== null && newSlug !== undefined && newSlug !== '') {
        componentInfo.value.slug = newSlug;
        await fnSite(componentInfo.value.slug);
    }
  },{ deep: true }
);

watch(
  () => route.params, // params 전체를 감시
  async (newParams, oldParams) => {
    componentInfo.value.slug = String(newParams.slug);
    await fnSite(componentInfo.value.slug);

    componentInfo.value.key += 1;

  },
  { deep: true } // 객체 내부를 깊게 감시
);

const fnSite = async (slug:string) => {
    let rst = await siteRep.GetSiteDetail(slug);
    if (rst.check) {
        siteStore.setSite(rst.data);
        componentInfo.value.key += 1;
    }
};


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["w-full flex justify-between pb-0 px-1 mb-5 border-b border-gray-300 dark:border-gray-800", property.class || '']),
        key: `head-${_unref(componentInfo).key}`
      }, [
        _createElementVNode("h3", null, _toDisplayString(site.value.name), 1),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, { to: "/main" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("HOME")
            ])),
            _: 1
          }),
          (property.title !== null && property.title !== undefined && property.title !== '')
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, " > "))
            : _createCommentVNode("", true),
          _createElementVNode("a", null, _toDisplayString(property.title), 1)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})