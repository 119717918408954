<template>
  <Container :key="`mainpage_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <Head title="" />
    <Jumbotron title="test" description="testest"></Jumbotron>

  </Container>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore } from '@/store';
import { TextBox, Container, Jumbotron } from '@gn2studio/gn2components';
import { Head } from '@/components';

const auth = new AuthService();
const site = useGlobalStore();

var pageinfo = ref({
    key:0,
    class:'',
    isLoaded:false
});

onMounted(async () => {
  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});
</script>
