import { DynamicSite } from '@gn2studio/gn2.library.common';
import { defineStore } from 'pinia';

export const useSiteStore = defineStore('siteStore', {
  state: () => ({
    slug: '',
    site:new DynamicSite()
  }),
  actions: {
    setValue(value: string) {
      this.slug = value;
      localStorage.setItem('slug', value);
    },
    getValue() {
      const result = localStorage.getItem('slug');
      return result !== null ? result : null;
    },
    clearValue() {
      this.slug = '';
      localStorage.removeItem('slug');
    },
    setSite(value: DynamicSite) {
      this.site = value;
      localStorage.setItem('site', JSON.stringify(value));
    },
    getSite():DynamicSite {
      let tmp = localStorage.getItem('site');
      if (tmp !== null && tmp !== undefined) {
        const result = JSON.parse(String(tmp)) as DynamicSite;
        return result !== null ? result : new DynamicSite();
      } else {
        return new DynamicSite();
      }
    },
    clearSite() {
      this.site = new DynamicSite();
      localStorage.removeItem('site');
    },
  },
});
