<template>
  <Container :key="`board_setup_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <div class="w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800">
      <h3 class="text-lg text-gray-800 dark:text-gray-100">{{pageinfo.site.name}} {{ t(`common.${pageinfo.master.title}`) }} 카테고리 관리</h3>
    </div>
    <div class="w-full mt-2 p-4">
      <div class="pb-2 mb-3 border-b border-dashed border-gray-300 dark:border-gray-800 flex justify-start space-x-2">
        <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnList()">목록</StyleButton>
        <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnAdd()">추가</StyleButton>
      </div>
      <div class="w-full text-gray-700 dark:text-gray-200 flex flex-col space-y-2" :key="`board_setup_list_${pageinfo.key}`">
        <div class="flex space-x-2" v-for="(item, index) in pageinfo.data" :key="`master_${index}`">
            <InputBox class="border border-gray-300 rounded-md text-gray-500 px-2 py-1" v-model="item.title" />
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnSave(item)">저장</StyleButton>
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnDelete(item)" v-if="item.id !== null && item.id !== undefined && String(item.id).trim() !== ''">삭제</StyleButton>
        </div>
      </div>

    </div>
  </Container>
</template>

<script setup lang="ts">
import { onMounted,ref,computed,watch } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { StyleButton,Container,InputBox } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, DynamicSite, BoardMaster,Member, ValidateHelper, KeyValuePair, SiteRepository, BoardCategory } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));
const masterCode = computed(() => String(route.params.masterCode));
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var boardRep = new BoardRepository(config.apis.board, '', slug.value);
var siteRep = new SiteRepository(config.apis.site, '');
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    category:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    site:new DynamicSite(),
    member:new Member(),
    data:[] as BoardCategory[],
    master:new BoardMaster(),
    masters:[] as BoardMaster[],
    categories:[] as KeyValuePair[]
});

onMounted(async () => {
  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : '';
  pageinfo.value.id = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '';

  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
    siteRep = new SiteRepository(config.apis.site, pageinfo.value.accessToken);
    pageinfo.value.isLoggedIn = true;
  }

  await getSite();
  await getMaster();
  await getCategories();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

watch([slug.value, masterCode.value],
    async (newSlug, newMasterCode) => {
        await getSite();
        await getMaster();
        await getCategories();
        pageinfo.value.key += 1;
    }
);

const getSite = async () => {
  let rst = await siteRep.GetSiteDetail(slug.value);
  if (rst.check) {
    pageinfo.value.site = rst.data;
  }
};

const getCategories = async () => {
  boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
  let rst = await boardRep.GetBoardCategories(pageinfo.value.masterCode);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
}

const getMaster = async () => {
    boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
    let rst = await boardRep.GetMaster(masterCode.value);
    if (rst.check) {
      pageinfo.value.master = rst.data;
    }
}

const fnDelete = (item:BoardCategory) => {
  MessageBox.Confirm("정말 삭제하시겠습니까?", async () => {
    let rst = await boardRep.MasterCategoryRemove(item.id);
    if (rst.check) {
      MessageBox.Success("삭제되었습니다.", async () => {
        await getMaster();
      });
    } else {
      MessageBox.Alert(rst.message);
    }
  });
}

const fnSave = async (item:BoardCategory) => {
  if (validate.IsNullOrWhiteSpace(item.title)) {
    MessageBox.Alert("제목을 입력하세요.");
    return;
  }

  boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
  let rst = await boardRep.MasterCategoryRegist(masterCode.value, slug.value, item.title);

  if (rst.check) {
    MessageBox.Success("저장되었습니다.", async () => {
      await getCategories();
      pageinfo.value.key += 1;
    });
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnAdd = () => {
    pageinfo.value.data.push(new BoardCategory());
};

const fnList = () => {
  router.push(`/board/${slug.value}/${masterCode.value}`);
}
</script>
