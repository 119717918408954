<template>
  <Container :key="`mainpage_${pageinfo.key}`" v-if="pageinfo.isLoaded">
    <div class="w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800">
      <h3 class="text-lg text-gray-800 dark:text-gray-100">{{ t(`common.${pageinfo.master.title}`) }}</h3>
    </div>
    <div class="w-full mt-2 p-4">
      <div class="w-full text-gray-700 dark:text-gray-200">
        <div class="flex space-x-2">
          <Dropdown placeholder="분류를 선택하세요." class="px-2 py-1 rounded-md text-gray-500" :items="pageinfo.categories" v-model="pageinfo.data.category" />
          <InputBox class="border border-gray-300 rounded-md text-gray-500 px-2 py-1 w-full" v-model="pageinfo.data.title" :placeholder="t('common.requiredtitlement')" />
        </div>
        <div class="w-full py-2">
          <div class="hidden lg:block mb-3">
              <MultiFileUpload :callback="fnMultiUploadCallback" />
          </div>
          <Editor
            :apiKey="config.editor.key"
            :key="`Editor-${pageinfo.key}`"
            :init="{
                height: 500,
                menubar: false,
                plugins: ['advlist','autolink','lists','link','image','wordcount','media','code'],
                toolbar:
                'undo redo | formatselect | bold italic backcolor | bullist numlist outdent indent | removeformat | image | media | code',
                images_upload_handler: uploadHandler,
                dropzone: {
                uploadMultiple: false,
                maxFiles: 10,
                acceptedFiles: 'image/*',
                success: handleDropzoneSuccess
                },
                relative_urls: false,
                document_base_url: config.apis.article
            }"
            v-model="pageinfo.data.content"
            :onInit="handleInit"
            ></Editor>
        </div>
        <div class="w-full py-2 border-t border-dashed border-gray-300 dark:border-gray-600 flex justify-between">
          <div>
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnCancel">취소</StyleButton>
          </div>
          <div class="flex justify-start space-x-2">
            <StyleButton size="md" class="rounded-md shadow-md py-1 px-3" @click="fnBoardSave">저장</StyleButton>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { onMounted,ref,computed } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { MultiFileUpload } from '@/components';
import { StyleButton,Container,InputBox, Dropdown } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { BoardRepository, FileRepository,BoardContent, BoardMaster,Member,ResourceData, BoardContentDTO,ValidateHelper, BoardCategory, KeyValuePair, ReturnValues } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { useI18n } from 'vue-i18n';
import Editor from '@tinymce/tinymce-vue'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import { MessageBox } from '@/utility';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const { t } = useI18n();
const slug = computed(() => String(route.params.slug));
const id = computed(() => String(route.params.id));
const memberStore = useMemberStore();
const validate = new ValidateHelper();

var boardRep = new BoardRepository(config.apis.board, '', slug.value);
var fileRep = new FileRepository(config.apis.resource, '', slug.value);
var pageinfo = ref({
    key:0,
    class:'',
    masterCode:'',
    accessToken:'',
    id:'',
    isLoaded:false,
    isLoggedIn:false,
    member:new Member(),
    data:new BoardContent(),
    master:new BoardMaster(),
    categories:[] as KeyValuePair[]
});

onMounted(async () => {
  pageinfo.value.masterCode = (route.params.masterCode !== null && route.params.masterCode !== undefined) ? String(route.params.masterCode) : 'notice';
  pageinfo.value.id = (route.params.id !== null && route.params.id !== undefined) ? String(route.params.id) : '';

  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    boardRep = new BoardRepository(config.apis.board, token, config.global.slug);
    fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
    pageinfo.value.isLoggedIn = true;
  }

  await getMaster();
  await getCategories();
  if (pageinfo.value.id !== null && pageinfo.value.id !== undefined) {
    await getBoard();
  }

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

const getBoard = async () => {
  let rst = await boardRep.GetContent(pageinfo.value.id);
  if (rst.check) {
    pageinfo.value.data = rst.data;
  }
};

const getMaster = async () => {
    let rst = await boardRep.GetMaster(pageinfo.value.masterCode);
    if (rst.check) {
      pageinfo.value.master = rst.data;
    }
}

const getCategories = async () => {
  boardRep = new BoardRepository(config.apis.board, pageinfo.value.accessToken, slug.value);
  let rst = await boardRep.GetBoardCategories(pageinfo.value.masterCode);
  if (rst.check) {
    let categories = rst.data;
    pageinfo.value.categories = categories.map((category:BoardCategory) => {
      return {
        key:t(`site.${category.title}`),
        value:category.id
      };
    });
  }
}

const handleInit = (editor: any) => {
  pageinfo.value.isLoaded = true;
};

const handleDropzoneSuccess = (images: any[]) => {
    try {
        const imageTags = images.map((image) => `<img src="${image.url}" alt="${image.name}" />`);
        pageinfo.value.data.content += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

const uploadHandler = (blobInfo: any): Promise<string> => {
    return new Promise(async (resolve, reject) => {
        try {
            let rst:any = await fileRep.FileUpload(blobInfo.blob());
            if (rst.check && rst.data !== null && rst.data !== undefined && rst.data.length > 0) {
                let newImages = rst.data[0].webURL;
                resolve(newImages);
            } else {
                if (rst !== null && rst !== undefined) {
                    if (rst.message !== null && rst.message !== undefined) {
                        reject(rst.message);
                    } else if (rst.errors !== null && rst.errors !== undefined && rst.errors.upload !== null && rst.errors.upload !== undefined && rst.errors.upload.length > 0) {
                        reject(rst.errors.upload[0]);
                    } else if (rst.title !== null && rst.title !== undefined) {
                        reject(rst.title);
                    } else {
                        reject('upload fail');
                    }
                } else {
                    console.debug('response is null or empty');
                    reject('response is null or empty');
                }
            }
        } catch (e:any) {
            console.debug(e.message);
            reject(e.message);
        }
    });
};

const fnMultiUploadCallback = (newImages:ResourceData[]) => {
    try {
        const imageTags = newImages.map((image) => `<img src="${image.webURL}" alt="${image.fileName}" />`);
        pageinfo.value.data.content += imageTags.join('');
    } catch (e: any) {
        console.log(e.message);
    }
};

const fnCancel = () => {
  MessageBox.Confirm("작성을 취소하시겠습니까?", () => {
    router.push(`/board/${slug.value}/${pageinfo.value.masterCode}`);
  });
}

const fnBoardSave = async () => {
  let dto = new BoardContentDTO();
  dto.title = pageinfo.value.data.title;
  dto.content = pageinfo.value.data.content;
  dto.category = pageinfo.value.data.category;
  dto.masterCode = pageinfo.value.masterCode;

  if (validate.IsNullOrWhiteSpace(dto.title)) {
    MessageBox.Alert('제목을 입력하세요.');
    return;
  }

  if (validate.IsNullOrWhiteSpace(dto.content)) {
    MessageBox.Alert('내용을 입력하세요.');
    return;
  }

  let rst = new ReturnValues();

  if (id.value !== null && id.value !== undefined && id.value !== '') {
    rst = await boardRep.UpdateContent(id.value, dto);
  } else {
    rst = await boardRep.RegistContent(dto);
  }
  
  if (rst.check) {
    MessageBox.Success('저장했습니다.', () => {
      router.push(`/board/${slug.value}/${pageinfo.value.masterCode}/${rst.data.id}`);
    });
  } else {
    MessageBox.Alert(rst.message);
  }
};
</script>
