import config from '@/config';
import { ArticleRepository, Categories } from '@gn2studio/gn2.library.common';
import { defineStore } from 'pinia';

interface LocalStorageData {
  data: Categories[];
  expiry: number; // 만료 시간 (Unix Timestamp)
}

export const useCategoryStore = defineStore('categoryStore', {
  state: () => ({
    categories: [] as Categories[], // 현재 저장된 카테고리 목록
  }),

  actions: {
    // 카테고리를 로컬스토리지에서 가져오거나 API를 호출
    async getCategories() {
      const localStorageKey = 'categories';
      const now = Date.now();

      // 로컬스토리지에서 데이터 가져오기
      const storedData = localStorage.getItem(localStorageKey);

      if (storedData) {
        const parsedData: LocalStorageData = JSON.parse(storedData);

        // 만료 시간이 유효하면 로컬 데이터를 반환
        if (parsedData.expiry > now) {
          this.categories = parsedData.data;
          return this.categories;
        }
      }

      // 만료되었거나 데이터가 없으므로 API 호출
      const articleRep = new ArticleRepository(config.apis.article, '');
      const rst = await articleRep.GetCategories(); // API 호출
      console.log('useContentStore getCategories : ', rst);
      if (rst.check) {
        this.categories = rst.data;
        this.saveCategoriesToLocalStorage(rst.data);
      }

      return this.categories;
    },

    // 로컬스토리지에 카테고리 저장
    saveCategoriesToLocalStorage(categories: Categories[]) {
      const localStorageKey = 'categories';
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

      const dataToStore: LocalStorageData = {
        data: categories,
        expiry: Date.now() + oneDayInMilliseconds, // 만료 시간: 24시간 후
      };

      localStorage.setItem(localStorageKey, JSON.stringify(dataToStore));
    },

    // 로컬스토리지 및 상태 초기화
    clearCategories() {
      const localStorageKey = 'categories';
      this.categories = [];
      localStorage.removeItem(localStorageKey);
    },
  },
});
