import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full mt-2 py-2" }
const _hoisted_2 = { class: "dark:text-gray-300" }
const _hoisted_3 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_4 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-left" }
const _hoisted_5 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_6 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_7 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_8 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_9 = { class: "border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400 text-center" }
const _hoisted_10 = { class: "w-full mt-1 pt-1 flex justify-between" }

import { onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { ArticleRepository, PagingParameter,Member, V_Member, Book } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'BookList',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();

var articleRep = new ArticleRepository(config.apis.article, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:[] as Book[],
    member:new Member()
});

onMounted(async () => {
  memberStore.loadFromLocalStorage();
  let token = await auth.getAccessToken();
  if (token !== null && token !== undefined && String(token).trim() !== '') {
    pageinfo.value.accessToken = token;
    pageinfo.value.member = memberStore.member;
    articleRep = new ArticleRepository(config.apis.article, pageinfo.value.accessToken);
  }

  await getBooks();

  pageinfo.value.isLoaded = true;

  setTimeout(() => {
    site.setValue('loading', false);
  }, 200);
});

const getBooks = async () => {
  let rst = await articleRep.GetBookListForManager(pageinfo.value.paging);
  if (rst.check) {
    pageinfo.value.data = rst.data;
    console.log('pageinfo.value.data : ', pageinfo.value.data);
    pageinfo.value.totalCount = rst.code;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnPaging = async (num:number) => {
  pageinfo.value.paging.curPage = num;
  await getBooks();
  pageinfo.value.key += 1;
}

const fnView = (item:V_Member) => {
  router.push(`/book/view/${item.id}`);
}

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(Container), {
        key: `memberpage_${_unref(pageinfo).key}`
      }, {
        default: _withCtx(() => [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "w-full pt-2 pb-1 border-b border-gray-200 dark:border-gray-800" }, [
            _createElementVNode("h3", { class: "text-lg text-gray-800 dark:text-gray-100" }, "도서관리")
          ], -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(DataGrid), {
                items: _unref(pageinfo).data,
                rowclick: fnView
              }, {
                columns: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "NO", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Title", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Writer", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Owner", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Category", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "Status", -1),
                  _createElementVNode("th", { class: "border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-600 dark:text-slate-200 text-center" }, "RegistDate", -1)
                ])),
                items: _withCtx(({ item, index }) => [
                  _createElementVNode("td", _hoisted_3, _toDisplayString((_unref(pageinfo).totalCount - index)), 1),
                  _createElementVNode("td", _hoisted_4, _toDisplayString(item.title), 1),
                  _createElementVNode("td", _hoisted_5, _toDisplayString(item.writer?.nickname), 1),
                  _createElementVNode("td", _hoisted_6, _toDisplayString(item.owner.nickName), 1),
                  _createElementVNode("td", _hoisted_7, _toDisplayString(item.majorCategory), 1),
                  _createElementVNode("td", _hoisted_8, _toDisplayString(item.condition.status), 1),
                  _createElementVNode("td", _hoisted_9, _toDisplayString(item.condition.registDate ? _unref(dayjs).utc(item.condition.registDate).local().format('YYYY-MM-DD HH:mm') : ''), 1)
                ]),
                _: 1
              }, 8, ["items"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex justify-start space-x-2" }, null, -1)),
              _createVNode(_unref(Pagination), {
                "cur-page": _unref(pageinfo).paging.curPage,
                "page-size": _unref(pageinfo).paging.pageSize,
                "total-count": _unref(pageinfo).totalCount,
                callback: fnPaging,
                class: "overflow-hidden rounded-md"
              }, null, 8, ["cur-page", "page-size", "total-count"])
            ])
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})