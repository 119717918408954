import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { class: "flex space-x-1 pr-3" }

import { useI18n } from 'vue-i18n';
import { ProfileButton,LogoutButton,LanguageSelector } from '@/components';
import { ThemeButton,Tab } from '@gn2studio/gn2components';
import { useRouter } from 'vue-router';
import { onMounted, ref, onUnmounted } from 'vue';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';
import { Member, MemberRepository, ValidateHelper } from '@gn2studio/gn2.library.common';
import config from '@/config';
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'SkyMenu',
  setup(__props) {

const { t, locale } = useI18n();
const router = useRouter();
const auth = new AuthService();
const memberStore = useMemberStore();
const currentPath = ref(location.pathname);
const validate = new ValidateHelper();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    menutab:0,
    member:null as Member|null,
    isShowSide:false,
    isLogin:false,
    tabitems:[
        { active:false, title:'GN2Studio' },
        { active:false, title:'MuseStudio' },
        { active:false, title:'Merrytoktok' }
    ]
});

onMounted(async () => {
    const accessToken = await auth.getAccessToken();

    if (accessToken !== null && accessToken !== undefined && String(accessToken).trim() !== '') {
        componentInfo.value.member = memberStore.member
        if (componentInfo.value.member === null 
        || componentInfo.value.member === undefined 
        || componentInfo.value.member.id === null 
        || componentInfo.value.member.id === undefined 
        || String(componentInfo.value.member.id).trim() === '')
        {
        const memberRep = new MemberRepository(config.apis.member, accessToken);
        const rst = await memberRep.GetUser();
        if (rst.check) {
            componentInfo.value.member = rst.data;
            
        } else {
            await auth.refreshSilent();
        }
            memberStore.setMember(rst.data);
        }

        componentInfo.value.isLogin = true;
        componentInfo.value.isLoaded = true;
    }

    window.addEventListener('popstate', () => {
        currentPath.value = location.pathname;
    });
});

onUnmounted(() => {
    window.removeEventListener('popstate', () => {
        currentPath.value = location.pathname;
    });
});

const fnLogout = () => {
    MessageBox.Confirm(t('system.logout_ment'), async () => {
        await auth.logout();
        memberStore.clearMember();
    });
};

const fnProfile = () => {
    router.push(`/profile`);
};

const TabClick = (item: any) => {
    switch (item.title) {
        case "GN2Studio":
            window.open('https://www.gn2studio.com');
            break;
        case "MuseStudio":
            window.open('https://muse.gn2studio.com');
            break;
        case "Merrytoktok":
            window.open('https://www.merrytoktok.com');
            break;
    }
}

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: "w-full border-b border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-900 sky",
        key: `sky-menu-${_unref(componentInfo).key}`
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_unref(Tab), {
              items: _unref(componentInfo).tabitems,
              size: "xs",
              itemclass: "px-3 py-1 rounded-t-md",
              class: "ml-3 h-[31px] items-end",
              onclick: TabClick
            }, null, 8, ["items"])
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(ProfileButton), {
                class: "rounded-lg h-[24px] mt-1 px-3",
                size: "xs",
                member: _unref(componentInfo).member,
                onClick: fnProfile
              }, null, 8, ["member"]),
              _createVNode(_unref(LogoutButton), {
                class: "rounded-lg h-[24px] mt-1 px-3",
                size: "xs",
                onClick: fnLogout
              }),
              _createVNode(_unref(ThemeButton)),
              _createVNode(_unref(LanguageSelector))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})