import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-6"
}
const _hoisted_2 = { class: "text-2xl font-bold mb-4" }
const _hoisted_3 = { class: "text-gray-700" }
const _hoisted_4 = { class: "text-lg text-pink-700" }
const _hoisted_5 = { class: "text-gray-500" }
const _hoisted_6 = {
  key: 1,
  class: "mb-6"
}
const _hoisted_7 = { class: "text-2xl font-bold mb-4" }
const _hoisted_8 = { class: "text-gray-700" }
const _hoisted_9 = { class: "text-gray-500" }
const _hoisted_10 = { href: "#" }
const _hoisted_11 = { class: "mt-4" }
const _hoisted_12 = { class: "text-2xl font-bold mb-4" }
const _hoisted_13 = { class: "py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider" }
const _hoisted_14 = { class: "py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider" }
const _hoisted_15 = { class: "py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider" }
const _hoisted_16 = { class: "py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider" }
const _hoisted_17 = { class: "py-2 px-4 border-b border-gray-200 bg-gray-50 text-left text-sm leading-4 font-medium text-gray-500 uppercase tracking-wider" }
const _hoisted_18 = { class: "py-2 px-4 border-b border-gray-200" }
const _hoisted_19 = { class: "py-2 px-4 border-b border-gray-200" }
const _hoisted_20 = { class: "py-2 px-4 border-b border-gray-200" }
const _hoisted_21 = { class: "py-2 px-4 border-b border-gray-200" }
const _hoisted_22 = { class: "py-2 px-4 border-b border-gray-200" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 1,
  class: "text-red-500 text-sm"
}
const _hoisted_25 = { class: "py-2 flex justify-center items-center" }

import { 
    Member,MemberRepository,ContentRepository,Content,FileRepository,Resource,ValidateHelper,MyMemberShip,PaymentHistory,StringHelper
    ,MemberShip
   } from '@gn2studio/gn2.library.common';
  import { onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useMemberStore,useGlobalStore } from '@/store';
  import { AuthService } from '@/services';
  import { ProfileSection } from '@/components';
  import { StyleButton,Pagination,Container,DataGrid } from '@gn2studio/gn2components';
  import config from '@/config';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import localizedFormat from 'dayjs/plugin/localizedFormat'; 
  import { useI18n } from 'vue-i18n';
  import { MessageBox } from '@/utility';
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

  dayjs.extend(utc);
  dayjs.extend(localizedFormat);

  const route = useRoute();
  const router = useRouter();
  const auth = new AuthService();
  const memberStore = useMemberStore();
  const site = useGlobalStore();
  const validate = new ValidateHelper();
  const { t, locale } = useI18n();

  var memberRep = new MemberRepository(config.apis.member, '');
  var contentRep = new ContentRepository(config.apis.content, '');
  var fileRep = new FileRepository(config.apis.resource, '', config.global.slug);
  var pageinfo = ref({
    key:0,
    isLoggedIn:false,
    isLoaded:false,
    accessToken:'',
    id:'',
    member: new Member(),
    user: new Member(),
    contents:[] as Content[],
    memberships:[] as MemberShip[],
    Free : new MemberShip(), 
    Premium : new MemberShip(), 
    Enterprice : new MemberShip(), 
    myMemberShip : new MyMemberShip(),
    myHistorian : [] as PaymentHistory[],
    files:[] as Resource[],
    totalcount:0,
    curpage:1,
  });


  onMounted(async () => {
    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.user = memberStore.member;
        pageinfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, token);
        contentRep = new ContentRepository(config.apis.article, token);
        fileRep = new FileRepository(config.apis.resource, token, config.global.slug);
    }

    if (route.params?.id !== null && route.params?.id !== undefined && String(route.params.id).trim() !== '') {
        pageinfo.value.id = String(route.params.id).trim();
        let rst = await memberRep.GetUserInfo(pageinfo.value.id);
        if (rst.check) {
            pageinfo.value.member = rst.data;
        } else {
            router.push(`/error/404`);
        }
    } else {
        if (pageinfo.value.isLoggedIn) {
            pageinfo.value.member = pageinfo.value.user;
        } else {
            router.push(`/error/404`);
        }
    }

    await GetMemberShips();
    await GetMyMemberShip();
    await fnPaging(1);

    setTimeout(() => {
      pageinfo.value.isLoaded = true;
      site.setValue('loading', false);
    }, 100);
  });

  const editProfile = () => {
      router.push(`/profile/edit`);
  }

  const GetMemberShips = async () => {
    let rst = await memberRep.GetMemberShips({ curPage : 1, pageSize : 10, keyword:'' });
    if (rst.check && rst.data !== null) {
      pageinfo.value.memberships = rst.data;
      pageinfo.value.Free = rst.data.filter((x:any) => x.title === "Basic")[0];
      pageinfo.value.Premium = rst.data.filter((x:any) => x.title === "Premium")[0];
      pageinfo.value.Enterprice = rst.data.filter((x:any) => x.title === "Professional")[0];
    }
  }

  const GetMyMemberShip = async () => {
    let rst = await memberRep.GetMyMemberShip();
    //console.log('GetMyMemberShip : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.myMemberShip = rst.data;
      
    }
  }

  const fnPaging = async (curpage:number) => {
    let rst = await memberRep.GetHistory({ curPage : curpage, pageSize : 10, keyword:'' });
    //console.log('GetMyMemberShips : ', rst);
    if (rst.check && rst.data !== null) {
      pageinfo.value.curpage = curpage;
      pageinfo.value.myHistorian = rst.data;
    }
  };

  const fnCancel = async (log:PaymentHistory) => {
    MessageBox.Confirm(t('site.cancelPaymentMent'), async () => {
      let rst = await memberRep.PutNicePayCancel(log.id);
      if (rst.check) {
        MessageBox.Success(t('common.paymentCancelled'), () => {
          log.status = "cancel";
        });
      }
    });
  };

return (_ctx: any,_cache: any) => {
  return (_unref(pageinfo).isLoaded)
    ? (_openBlock(), _createElementBlock("div", {
        class: "min-h-screen h-full text-gray-800 dark:text-gray-200",
        key: _unref(pageinfo).key
      }, [
        _createVNode(_unref(ProfileSection), {
          member: _unref(pageinfo).member,
          class: "mt-5"
        }, {
          default: _withCtx(() => [
            (_unref(pageinfo).isLoggedIn && _unref(pageinfo).member.id === _unref(pageinfo).user.id)
              ? (_openBlock(), _createBlock(_unref(StyleButton), {
                  key: 0,
                  onClick: editProfile,
                  class: "mt-2 px-4 py-1",
                  size: "lg",
                  type: "default"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Edit Profile ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["member"]),
        _createVNode(_unref(Container), { class: "mt-8 p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              (_unref(pageinfo).myMemberShip !== null && _unref(pageinfo).myMemberShip !== undefined && String(_unref(pageinfo).myMemberShip.id).trim() !== '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(t)('common.CurrentMembership')), 1),
                    _createElementVNode("p", _hoisted_3, [
                      _createElementVNode("b", _hoisted_4, _toDisplayString(_unref(pageinfo).myMemberShip.memberShip?.title), 1),
                      _createTextVNode(" " + _toDisplayString(_unref(t)('common.membership')), 1)
                    ]),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)('common.ExpiresOn')) + ": " + _toDisplayString(_unref(dayjs).utc(_unref(pageinfo).myMemberShip.expiredDate).local().format('YYYY-MM-DD HH:mm')), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("h2", _hoisted_7, _toDisplayString(_unref(t)('common.CurrentMembership')), 1),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)('common.Nohavemembership')), 1),
                    _createElementVNode("p", _hoisted_9, [
                      _createElementVNode("a", _hoisted_10, _toDisplayString(_unref(t)('common.Paymembership')), 1)
                    ])
                  ])),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("h2", _hoisted_12, _toDisplayString(_unref(t)('common.PurchaseHistory')), 1),
                _createVNode(_unref(DataGrid), {
                  items: _unref(pageinfo).myHistorian
                }, {
                  columns: _withCtx(() => [
                    _createElementVNode("th", _hoisted_13, _toDisplayString(_unref(t)('common.membership')), 1),
                    _createElementVNode("th", _hoisted_14, _toDisplayString(_unref(t)('common.Price')), 1),
                    _createElementVNode("th", _hoisted_15, _toDisplayString(_unref(t)('common.OrderNo')), 1),
                    _createElementVNode("th", _hoisted_16, _toDisplayString(_unref(t)('common.RegistDate')), 1),
                    _createElementVNode("th", _hoisted_17, _toDisplayString(_unref(t)('common.PaymentCancel')), 1)
                  ]),
                  items: _withCtx(({ item, index }) => [
                    _createElementVNode("td", _hoisted_18, _toDisplayString(item.goodsName), 1),
                    _createElementVNode("td", _hoisted_19, "￦" + _toDisplayString(_unref(StringHelper).formatNumberWithCommas(item.amount)), 1),
                    _createElementVNode("td", _hoisted_20, _toDisplayString(item.orderId), 1),
                    _createElementVNode("td", _hoisted_21, _toDisplayString(_unref(dayjs).utc(item.paidAt).local().format('YYYY-MM-DD HH:mm')), 1),
                    _createElementVNode("td", _hoisted_22, [
                      (item.status !== null && item.status !== undefined && String(item.status).trim() === 'paid')
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            class: "py-1 px-2 bg-orange-400 text-white text-mini",
                            onClick: ($event: any) => (fnCancel(item))
                          }, _toDisplayString(_unref(t)('common.PaymentCancel')), 9, _hoisted_23))
                        : _createCommentVNode("", true),
                      (item.status !== null && item.status !== undefined && String(item.status).trim() === 'cancel')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_unref(t)('common.CancelComplete')), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["items"])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_unref(Pagination), {
                  totalCount: _unref(pageinfo).totalcount,
                  curPage: _unref(pageinfo).curpage,
                  size: "md",
                  "page-size": 10,
                  callback: fnPaging
                }, null, 8, ["totalCount", "curPage"])
              ])
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})