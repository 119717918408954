import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "p-6 space-y-6 text-gray-700 dark:text-white" }
const _hoisted_3 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "flex items-center justify-between border-b pb-4" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "flex justify-end p-6" }

import { computed, onMounted,ref } from 'vue';
import { AuthService } from '@/services';
import { useGlobalStore,useSiteStore,useMemberStore } from '@/store';
import { Container, Pagination,StyleButton,DataGrid, BooleanBadge } from '@gn2studio/gn2components';
import { useRoute,useRouter } from 'vue-router';
import { MemberRepository, PagingParameter,Member, V_Member } from '@gn2studio/gn2.library.common';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat'; 
import config from '@/config';
import { Head } from '@/components';
import { MessageBox } from '@/utility';


export default /*@__PURE__*/_defineComponent({
  __name: 'MemberView',
  setup(__props) {

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const auth = new AuthService();
const site = useGlobalStore();
const route = useRoute();
const router = useRouter();
const siteStore = useSiteStore();
const memberStore = useMemberStore();
const { t } = useI18n();
const Id = computed(() => route.params.id);

var memberRep = new MemberRepository(config.apis.member, '');
var pageinfo = ref({
    key:0,
    class:'',
    accessToken:'',
    isLoaded:false,
    totalCount:0,
    paging:new PagingParameter(),
    data:new V_Member(),
    member:new Member()
});

onMounted(async () => {
    if (Id.value === null || Id.value === undefined || String(Id.value).trim() === '') {
        router.push(`/error/500`);
    }

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        pageinfo.value.accessToken = token;
        pageinfo.value.member = memberStore.member;
        memberRep = new MemberRepository(config.apis.member, pageinfo.value.accessToken);
    }

    await getMember();

    pageinfo.value.isLoaded = true;

    setTimeout(() => {
        site.setValue('loading', false);
    }, 200);
});

const getMember = async () => {
  let rst = await memberRep.GetMemberDetail(String(Id.value));
  if (rst.check) {
    pageinfo.value.data = rst.data;
  } else {
    MessageBox.Alert(rst.message);
  }
};

const fnList = async () => {
    router.push(`/member/list`);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Container), { class: "p-8" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "bg-gray-600 text-white text-center py-6 rounded-md" }, [
          _createElementVNode("h3", { class: "text-2xl font-bold" }, "Member Information")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "font-medium" }, "Email:", -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(pageinfo).data.email), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "font-medium" }, "Email Confirmed:", -1)),
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_unref(BooleanBadge), {
                check: _unref(pageinfo).data.emailConfirmed ?? false
              }, null, 8, ["check"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "font-medium" }, "ID:", -1)),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_unref(pageinfo).data.id), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "font-medium" }, "Username:", -1)),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(pageinfo).data.userName), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "font-medium" }, "Phone Number:", -1)),
            _createElementVNode("span", _hoisted_12, _toDisplayString(_unref(pageinfo).data.phoneNumber), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-medium" }, "Phone Number Confirmed:", -1)),
            _createElementVNode("span", _hoisted_14, [
              _createVNode(_unref(BooleanBadge), {
                check: _unref(pageinfo).data.phoneNumberConfirmed ?? false
              }, null, 8, ["check"])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "font-medium" }, "Registration Date:", -1)),
            _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(pageinfo).data.registDate ? _unref(dayjs).utc(_unref(pageinfo).data.registDate).local().format('YYYY-MM-DD HH:mm') : ''), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "font-medium" }, "Status:", -1)),
            _createElementVNode("span", _hoisted_18, _toDisplayString(_unref(pageinfo).data.status), 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "font-medium" }, "User Type:", -1)),
            _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(pageinfo).data.type), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createVNode(_unref(StyleButton), {
            class: "ml-2 py-2 px-4",
            onClick: fnList
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("List")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})