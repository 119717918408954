<template>
<div class="w-full" :class="componentInfo.class || ''" v-if="componentInfo.isLoaded" :key="`navbar-${componentInfo.key}`">
    <div class="w-full p-3 mb-1">
        <h3 class="dark:text-gray-200">통합설정</h3>
        <ul class="text-left text-gray-800 border-t border-b border-gray-400 dark:border-gray-700">
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <router-link class="border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5" to="/member/list">회원관리</router-link>
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <router-link class="border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5" to="#">번역그룹관리</router-link>
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Accordion :items="componentInfo.contents" :link-func="fnLink" :title="`컨텐츠관리`" :key="`content-${componentInfo.key}`" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Accordion :items="componentInfo.payment" :link-func="fnLink" :title="`결제관리`" :key="`payment-${componentInfo.key}`" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <router-link class="border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5" to="#">사이트관리</router-link>
            </li>
        </ul>
    </div>
    <div class="w-full p-3 mb-1" v-for="site in componentInfo.sites.filter(x => x.slug !== 'Management')" :key="`site-${site.id}`">
        <h3 class="dark:text-gray-200">{{ site.name }}</h3>
        <ul class="text-left text-gray-800 border-t border-b border-gray-400 dark:border-gray-700">
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <router-link class="border-t flex items-center dark:bg-gray-800 dark:border-gray-800 dark:text-gray-200 focus:border-teal-600 outline-none p-1.5" :to="`/board/set/${site.slug}`">게시판 관리</router-link>
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Accordion :items="fnSiteArray(site.slug)" :link-func="fnBoardLink" :prefix="site.slug" :title="`게시판 관리`" :key="`board-${componentInfo.key}`" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Accordion :items="fnLanguageArray(site.slug)" :link-func="fnTransLink" :prefix="site.slug" :title="`번역 관리`" :key="`translation-${componentInfo.key}`" />
            </li>
        </ul>
    </div>
</div>
<div v-else class="w-full" :class="componentInfo.class || ''">
    <div class="w-full p-3 mb-1">
        <Skeleton class="w-[100px] h-[25px] my-2" />

        <ul class="text-left text-gray-200 border-t border-b border-gray-400 dark:border-gray-700">
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Skeleton class="w-[90%] h-[22px] my-2" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Skeleton class="w-[90%] h-[22px] my-2" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Skeleton class="w-[90%] h-[22px] my-2" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Skeleton class="w-[90%] h-[22px] my-2" />
            </li>
            <li class="border-b border-dashed border-gray-300 dark:border-gray-800 last-hide">
                <Skeleton class="w-[90%] h-[22px] my-2" />
            </li>
        </ul>
    </div>
</div>
</template>

<script setup lang="ts">
import { onMounted,ref } from 'vue';
import { BoardMaster,LinkItem,BoardRepository,Member,MemberRepository,DynamicSite,SiteRepository,TranslationRepository } from "@gn2studio/gn2.library.common";
import { useMemberStore } from '@/store';
import { Accordion, Skeleton } from '@gn2studio/gn2components';
import { AuthService } from '@/services';
import config from '@/config';
import router from '@/router';
import { useI18n } from 'vue-i18n';

interface navParameter {
    class?:string|null
};

const { t, locale } = useI18n();
const property = defineProps<navParameter>();
const memberStore = useMemberStore();

const auth = new AuthService();
var boardRep = new BoardRepository(config.apis.board, '', '');
var memberRep = new MemberRepository(config.apis.member, '');
var siteRep = new SiteRepository(config.apis.site, '');
var transRep = new TranslationRepository(config.apis.translation, '', '');
var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    accessToken:'', 
    member:new Member(),
    sites:[] as DynamicSite[],
    masters:[] as BoardMaster[],
    menus: {
        studio:[] as LinkItem[],
        Management:[] as LinkItem[],
        muse:[] as LinkItem[],
        merrytoktok:[] as LinkItem[],
    },
    languages: {
        studio:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        Management:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        muse:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
        merrytoktok:[{ title : '언어관리', url : '#', active : false, type: 'default' },{ title : '번역툴', url : '#', active : false, type: 'default' }] as LinkItem[],
    },
    contents:[
        { title : '도서관리', url : '/book/list', active : false, type: 'default' },
        { title : '작가관리', url : '#', active : false, type: 'default' },
        { title : '리뷰관리', url : '#', active : false, type: 'default' },
        { title : '메모관리', url : '#', active : false, type: 'default' }
    ] as LinkItem[],
    payment:[
        { title : '결제내역관리', url : '#', active : false, type: 'default' },
        { title : '상품관리', url : '#', active : false, type: 'default' }
    ] as LinkItem[],
    isLoggedIn:false
});

onMounted(async () => {
    componentInfo.value.class = property.class ?? '';

    memberStore.loadFromLocalStorage();
    let token = await auth.getAccessToken();
    if (token !== null && token !== undefined && String(token).trim() !== '') {
        componentInfo.value.accessToken = token;
        componentInfo.value.member = memberStore.member;
        componentInfo.value.isLoggedIn = true;
        memberRep = new MemberRepository(config.apis.member, componentInfo.value.accessToken);
        transRep = new TranslationRepository(config.apis.translation, componentInfo.value.accessToken, '');
        siteRep = new SiteRepository(config.apis.site, componentInfo.value.accessToken);
    }

    await fnSite();

    componentInfo.value.isLoaded = true;
});

const fnMasters = async (slug:string) => {
    let result = [] as LinkItem[];
    switch (slug) {
        case 'studio':
        case 'muse':
        case 'merrytoktok':
            boardRep = new BoardRepository(config.apis.board, componentInfo.value.accessToken, slug);
            let rst = await boardRep.GetMasters({ curPage : 1, pageSize : 50, keyword : '' });
            if (rst.check) {
                for (let i = 0; i < rst.data.length; i++) {
                    result.push({ 
                        title : t(`common.${rst.data[i].title}`), 
                        url : rst.data[i].masterCode, 
                        active : false, 
                        type: 'default' 
                    });
                }
            }
            break;
    }
    return result;
}

const fnSite = async () => {
    let rst = await siteRep.GetSites();
    if (rst.check) {
        componentInfo.value.sites = rst.data;
        for (let i = 0; i < componentInfo.value.sites.length; i++) {
            let site = componentInfo.value.sites[i];
            if (site !== null && site.slug !== null) {
                let bds = await fnMasters(site.slug);
                switch (site.slug) {
                    case 'studio':
                    case 'muse':
                    case 'merrytoktok':
                        componentInfo.value.menus[site.slug] = bds;
                        break;
                }
            }
        }
    }
};

const fnBoardLink = (masterCode:string, slug:string|null|undefined) => {
    if (slug === null || slug === undefined) return;
    router.push(`/board/${slug}/${masterCode}`);
    componentInfo.value.key += 1;
}

const fnTransLink = (languageCode:string, slug:string|null|undefined) => {
    if (slug === null || slug === undefined) return;
    router.push(`/translation/form/${slug}`);
    componentInfo.value.key += 1;
}

const fnSiteArray = (slug:string) => {
    switch (slug) {
        case 'studio':
            return componentInfo.value.menus.studio;
        case 'muse':
            return componentInfo.value.menus.muse;
        case 'merrytoktok':
            return componentInfo.value.menus.merrytoktok;
        default:
            return [];
    }
}


const fnLanguageArray = (slug:string) => {
    switch (slug) {
        case 'studio':
            return componentInfo.value.languages.studio;
        case 'muse':
            return componentInfo.value.languages.muse;
        case 'merrytoktok':
            return componentInfo.value.languages.merrytoktok;
        default:
            return [];
    }
}

const fnLink = (url:string) => {
    //router.push(url);
    document.location.href = url;
    componentInfo.value.key += 1;
}
</script>