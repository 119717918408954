import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { AuthCheck,AuthClear,AuthRefresh } from '../views';
import { MainView } from '../views';
import { ProfileView,ProfileEditView } from '../views';
import { Login,Error } from '../views';
import { MasterList,MasterView,MasterEdit,MaseterSet,MaseterCategory } from '../views';
import { TranslationForm } from '../views';
import { MemberList,MemberView } from '../views';
import { BookList,BookView } from '../views';

const routes: Array<RouteRecordRaw> = [
  { path: '/signin-oidc',name: 'signin',component: AuthCheck },
  { path: '/signout-oidc',name: 'signout1',component: AuthClear },
  { path: '/signout-callback-oidc',name: 'signout2',component: AuthClear },
  { path: '/silent-renew-oidc',name: 'signrefresh',component: AuthRefresh },
  { path: '/main', name: 'main',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/main', component:MainView },
  ] },
  { path: '/board', name: 'board',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/board/:slug/:masterCode', component:MasterList },
    { path:'/board/set/:slug', component:MaseterSet },
    { path:'/board/category/:slug/:masterCode', component:MaseterCategory },
    { path:'/board/:slug/:masterCode/:id', component:MasterView },
    { path:'/board/:slug/:masterCode/edit', component:MasterEdit },
    { path:'/board/:slug/:masterCode/edit/:id', component:MasterEdit },
  ] },
  { path: '/translation', name: 'translation',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/translation/form/:slug', component:TranslationForm }
  ] },
  { path: '/book', name: 'books',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/book/list', component:BookList },
    { path:'/book/view/:id', component:BookView }
  ] },
  { path: '/member', name: 'members',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/member/list', component:MemberList },
    { path:'/member/view/:id', component:MemberView }
  ] },
  { path: '/profile', name: 'profile',component: () => import('../layouts/Default.vue'), children: [ 
    { path:'/profile', component:ProfileView },
    { path:'/profile/edit', component:ProfileEditView },
    { path:'/profile/:id', component:ProfileView },
  ] },
  { path: '/', name: 'member',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/', component:Login },
  ] },
  { path: '/error', name: 'error',component: () => import('../layouts/Empty.vue'), children: [ 
    { path:'/error/:code', component:Error },
  ] },
]

const router = createRouter({
  history: createWebHistory(""),
  routes
})

export default router
