import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-start space-x-1" }
const _hoisted_2 = { class: "hidden lg:block font-normal mt-0.5" }

import { Member } from '@gn2studio/gn2.library.common';
import { onMounted, ref } from 'vue';
import { CircleImage } from '../molecules';
import { StyleButton } from '@gn2studio/gn2components';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    member?:Member|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileButton',
  props: {
    class: {},
    size: {},
    member: {}
  },
  setup(__props: any) {

;

const property = __props;

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'sm',
    member:new Member()
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
    componentInfo.value.member = property.member ?? new Member();
    componentInfo.value.isLoaded = true;
});

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(StyleButton), {
        class: _normalizeClass(_unref(componentInfo).class),
        size: _unref(componentInfo).size,
        key: `ProfileButton_${_unref(componentInfo).key}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_unref(CircleImage), {
              src: _unref(componentInfo).member?.photo,
              width: "20px",
              height: "20px",
              border: 1
            }, null, 8, ["src"]),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(componentInfo).member?.nickName), 1)
          ])
        ]),
        _: 1
      }, 8, ["class", "size"]))
    : _createCommentVNode("", true)
}
}

})