import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import { StyleButton } from '@gn2studio/gn2components';
import { AuthService } from '@/services';
import { useMemberStore } from '@/store';

interface containerParameter {
    class?:string|null|undefined,
    size?:string|null|undefined,
    clickEvent?:Function|null|undefined
}
export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutButton',
  props: {
    class: {},
    size: {},
    clickEvent: { type: [Function, null] }
  },
  setup(__props: any) {

;

const property = __props;
const auth = new AuthService();
const memberStore = useMemberStore();

var componentInfo = ref({
    key:0,
    isLoaded:false,
    class:'',
    size:'sm'
});

onMounted(() => {
    componentInfo.value.class = property.class ?? '';
    componentInfo.value.size = property.size ?? 'sm';
    componentInfo.value.isLoaded = true;
});

return (_ctx: any,_cache: any) => {
  return (_unref(componentInfo).isLoaded)
    ? (_openBlock(), _createBlock(_unref(StyleButton), {
        class: _normalizeClass(_unref(componentInfo).class),
        size: _unref(componentInfo).size,
        key: `LogoutButton_${_unref(componentInfo).key}`
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fa-solid fa-right-from-bracket" }, null, -1),
          _createElementVNode("span", { class: "hidden lg:block pl-1" }, "SIGN OUT", -1)
        ])),
        _: 1
      }, 8, ["class", "size"]))
    : _createCommentVNode("", true)
}
}

})